import React from 'react';
import { FavoriteGroups, MemoizedNavigationGroup } from 'src/components/NavigationGroup/NavigationGroup';
import styles from './Sidenav.styles';
import { Props } from './Sidenav.container';
import { extractNavPath, getSectionForPath } from 'src/pages/NavigationShell/navigationUtils';
import { chain, map, getOrElse } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/function';
import { isEmpty, isEqual } from 'lodash';
import ServiceContainer from 'src/ServiceContainer';
import { FavoritesEntry } from 'src/services/Favorites';
import qs from 'query-string';

type SideNavState = {
  collapsed: boolean;
  scrollbarShowed: boolean;
};

export class SideNav extends React.Component<Props, SideNavState> {
  sidenavRef!: HTMLDivElement | null;

  constructor(props: Props) {
    super(props);

    this.state = {
      collapsed: this.props.collapsed ? true : false,
      scrollbarShowed: false,
    };

    window.addEventListener('resize', this.checkForScrollbar);
  }
  // The first time a tab mounts, we should ensure that we have expanded something.
  getTabName(url: string) {
    if (!url) {
      return undefined;
    }
    const start = url.split('/', 3).join('/').length + 1;
    const end = url.split('/', 4).join('/').length;
    const tab = url.substr(start, end - start);
    return tab;
  }
  // TODO: I'm not at all sure that this is correct, but the router doesn't
  // Seem to provide any other way of accessing this information easily
  // This tightly couples sidenav to the global path structure...
  getGroupId() {
    return pipe(
      extractNavPath(this.props.routerLocation),
      chain((navPath) => getSectionForPath(this.props.tab, navPath)),
      map((section) => section.id),
      getOrElse(() => (this.props.tab.boundSections?.length ? this.props.tab.boundSections[0].id : ''))
    );
  }
  componentDidMount() {
    if (
      (!this.props.groupId || !isEqual(this.props.groupId, this.getTabName(this.props.routerLocation.pathname))) &&
      this.props.tab.boundSections
    ) {
      const setGroupId = this.getGroupId();
      this.props.setFocusedGroup(setGroupId);
    }

    if (this.sidenavRef) {
      this.checkForScrollbar();
    }
    if (this.props.showBookmarks && this.props.loadFavorites) {
      this.props.loadFavorites(ServiceContainer.axios);
    }
  }
  componentDidUpdate(prevProps: Props): void {
    if (
      !isEqual(
        this.getTabName(prevProps.routerLocation.pathname),
        this.getTabName(this.props.routerLocation.pathname)
      ) &&
      this.props.tab.boundSections
    ) {
      const setGroupId = this.getGroupId();
      // This allows opening & closing sections while preventing automatic closing on tab navigation
      if (prevProps.groupId !== setGroupId) {
        this.props.setFocusedGroup(setGroupId);
      }
    }

    if (!isEqual(this.props.showBookmarks, prevProps.showBookmarks)) {
      if (this.props.showBookmarks && this.props.loadFavorites) {
        this.props.loadFavorites(ServiceContainer.axios);
      }
    }
  }
  componentWillUnmount = () => {
    this.props.setCollapsed(this.state.collapsed);
  };

  toggleCollapsed = (isCollapsed?: boolean) => {
    const collapsed = isCollapsed === undefined ? !this.state.collapsed : isCollapsed;
    this.props.setCollapsed(collapsed);

    this.setState({
      collapsed,
    });
  };

  checkForScrollbar = () => {
    if (this.sidenavRef && !this.state.scrollbarShowed && this.sidenavRef.clientHeight < this.sidenavRef.scrollHeight) {
      this.setState({
        scrollbarShowed: true,
      });
    }
    if (
      this.sidenavRef &&
      this.state.scrollbarShowed &&
      this.sidenavRef.clientHeight === this.sidenavRef.scrollHeight
    ) {
      this.setState({
        scrollbarShowed: false,
      });
    }
  };
  onFaveRemoveClick = (e: React.MouseEvent<HTMLElement>, fave: FavoritesEntry) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.removeFavorite) {
      this.props.removeFavorite(ServiceContainer.axios, fave.key);
    }
  };

  render() {
    if (this.props.isPrintMode) {
      return null;
    }

    const { className, perspective, tab, sectionName } = this.props;
    const viewFromGroup = pipe(
      extractNavPath(this.props.routerLocation),
      chain((navPath) => getSectionForPath(tab, navPath)),
      map((section) => section.id),
      getOrElse(() => '')
    );
    const mfpFavoriteView = this.props.showBookmarks && !isEmpty(this.props.mfpFavorites) && (
      <FavoriteGroups
        favorites={this.props.mfpFavorites}
        pathname={this.props.routerLocation.pathname}
        groupId={this.props.groupId}
        onFaveRemoveClick={this.onFaveRemoveClick}
        queryString={qs.parse(this.props.routerLocation.search)}
        scopeId={this.props.mfpScopeId}
        onItemClick={() => this.props.setCollapsed(this.state.collapsed)}
        tab={tab}
        onlyIndirectMfpScoping={this.props.onlyIndirectMfpScoping}
        mfpScopeReady={this.props.mfpScopeReady}
      />
    );
    const sidenavGroups = tab.boundSections
      ? [
          ...tab.boundSections,
          {
            id: 'favorites',
            name: `${this.props.t('favorite', { count: 2, postProcess: 'capitalize' })}`,
            icon: 'far fa-bookmark',
            pathSlot: '',
            boundViews: [],
            defaultViewId: undefined,
            disabled: false,
          },
        ].map((section, i) => {
          if (section.id === 'favorites' && !mfpFavoriteView) return;
          return (
            <div key={i} style={{ display: section.hidden ? 'none' : undefined }}>
              <MemoizedNavigationGroup
                setFocusedGroup={(groupId: string) => this.props.setFocusedGroup(groupId)}
                expanded={this.props.groupId === section.id}
                activeViewFrom={viewFromGroup === section.id}
                perspective={perspective}
                tab={tab}
                section={section}
                key={section.name}
                collapsed={this.state.collapsed}
                onItemClick={() => this.props.setCollapsed(this.state.collapsed)}
                mfpFavoritesBoundViews={mfpFavoriteView || undefined}
                onlyIndirectMfpScoping={this.props.onlyIndirectMfpScoping}
                mfpScopeReady={this.props.mfpScopeReady}
                tooltip={section.tooltip}
              />
              <hr />
            </div>
          );
        })
      : null;

    const sidenavClasses = [className, styles.sidebar(this.state.scrollbarShowed), 'p-0', 'border-right'];
    if (this.state.collapsed) {
      sidenavClasses.unshift(styles.collapsedSidenavStyles(this.state.scrollbarShowed));
    }
    return (
      <React.Fragment>
        {this.state.collapsed ? (
          <div className={styles.fakeSidenavDivForSpacing(this.state.scrollbarShowed)} />
        ) : undefined}
        <div ref={(ref) => (this.sidenavRef = ref)} className={sidenavClasses.join(' ')}>
          <div className={`${styles.title} text-center text-secondary font-weight-bold pt-4`}>
            <span className="sectionNameDisplayed">{sectionName.toUpperCase()}</span>
          </div>
          <span
            onClick={() => this.toggleCollapsed()}
            className={'collapseIconContainer fas fa-angle-' + (this.state.collapsed ? 'right' : 'left')}
          />
          <hr style={{ marginTop: -5 }} />
          {sidenavGroups}
        </div>
      </React.Fragment>
    );
  }
}
