import { AnyAction, Dispatch } from 'redux';
import { EMPTY, Observable, Subscription, catchError, retry } from 'rxjs';
import { ServerSentEventConnectionError } from 'src/services/ServerSentEvents/ServerSentEventErrors';
import { EventSourceContext } from 'src/services/ServerSentEvents/ServerSentEventSource.types';

export abstract class BaseEventSource {
  // total of 3 tries before entering failure state
  static MAX_RECONNECT_ATTEMPTS = 2;

  // 5 seconds
  static RETRY_DELAY = 5000;

  protected abstract context: EventSourceContext;

  constructor(protected onErrorCallback: (error: ServerSentEventConnectionError) => void) {}

  protected abstract streamListener(dispatch: Dispatch<AnyAction>, message: Event): void;

  protected abstract eventSource(accessToken: string): Observable<Event>;

  protected createEventSource = (token: string): Observable<Event> => {
    return this.eventSource(token).pipe(
      retry({
        delay: BaseEventSource.RETRY_DELAY,
        count: BaseEventSource.MAX_RECONNECT_ATTEMPTS,
      }),
      catchError((_error) => {
        // provided error has no useful info, create custom error for callback
        const sseError = new ServerSentEventConnectionError(this.context);
        this.onErrorCallback(sseError);
        return EMPTY;
      })
    );
  };

  public abstract connect(dispatch: Dispatch<AnyAction>, accessToken$: Observable<string>): Subscription;
}
