import axios, { AxiosInstance } from 'axios';
import { isEqual, isNil } from 'lodash';
import { BindAll } from 'lodash-decorators';
import { CellRange } from '../components/Mfp/Pivot/AgPivot.types';
import Pivot, { CellPosition } from '../services/Pivot.service';
import { Field } from './Field';
import Intersection from './Intersection';
import {
  PivotCell,
  CELL_TAG_DISABLED,
  CELL_TAG_FROZEN,
  CELL_TAG_LOCKED,
  CELL_LOCK_STATES,
  CELL_TAG_RELAXED,
} from './PivotCell';
import PivotConfig from './PivotConfig';

export interface PivotManagerProps {
  config: PivotConfig;
  pivotManager?: PivotManager;
  axios?: AxiosInstance;
  cellRange?: CellRange;
  pivotName: string;
}

export interface iPivotManager {
  config: PivotConfig;
  createContext(): Promise<string | undefined>;
  getIntersectionKey(row: number, col: number): string | undefined;
  setCell(r: number, c: number, cell: PivotCell): void;
  getCell(row: number, col: number): PivotCell | undefined;
  getRow(row: number): PivotCell[];
  inRange(r: number, c: number): boolean;
  getRowCount(): number;
  getColCount(): number;
  getRowIntersection(r: number): Intersection;
  getColIntersection(c: number): Intersection;
  getRowHeaderField(r: number, c: number): Field | null;
  getLastHeaderFieldInRow(r: number): Field;
  getColHeaderField(r: number, c: number): Field | null;
  getLastHeaderFieldInCol(c: number): Field;
  rowMatchesNext(r: number): boolean;
  colMatchesNext(c: number): boolean;
  isCellLoaded(r: number, c: number): boolean | undefined;
  relaxCells(cells: PivotCell[]): Promise<boolean>;
  lockCells(cells: PivotCell[]): Promise<boolean>;
  constrainCells(cells: PivotCell[]): Promise<boolean>;
  forAllPresent: (fn: (cell: PivotCell, r: number, c: number) => void) => void;
  toggleLockCells(cells: PivotCell[], lockState: CELL_LOCK_STATES): Promise<boolean>;
  updateCell(r: number, c: number, v: string): Promise<void>;
  updateCells(cells: PivotCell[]): Promise<any>;
  loadMoreCells(range: CellRange): Promise<any>;
  syncCells(loadedCells: CellPosition[]): void;
}

@BindAll()
export default class PivotManager implements iPivotManager {
  public config: PivotConfig;
  protected rcIdToCell: Map<string, PivotCell>;
  protected rowIntersections: Intersection[];
  protected colIntersections: Intersection[];
  protected contextId!: string;
  protected contextIsLoading: boolean;
  protected axios: AxiosInstance;
  protected pivotService: Pivot;
  private pivotName: string;
  protected currentReqCellRange: CellRange[];

  public constructor(props: PivotManagerProps) {
    this.config = props.config;
    this.contextIsLoading = false;
    const name = props.pivotName || 'default';
    this.pivotName = name;

    this.rowIntersections = this.config.getRowIntersections();
    this.colIntersections = this.config.getColIntersections();
    this.rcIdToCell = new Map();
    this.axios = props.axios || axios;
    this.pivotService = new Pivot(this.axios, name);
    this.currentReqCellRange = [];

    const otherPivotManager = props.pivotManager;

    if (otherPivotManager) {
      const intersectionToCell = new Map<string, PivotCell>();
      otherPivotManager.forAllPresent((cell, r, c) => {
        if (r < 0 || c < 0) {
          // defensive checks against obscure bad configs
          // eslint-disable-next-line no-console
          console.log(cell, 'This cell call is out of bounds');
          return;
        }
        intersectionToCell.set(otherPivotManager.getIntersectionKey(r, c)!, cell);
      });

      let cellRange = props.cellRange;
      if (!cellRange) {
        cellRange = {
          startRow: 0,
          startColumn: 0,
          endRow: this.rowIntersections.length - 1,
          endColumn: this.colIntersections.length - 1,
        };
      }
      const endRow = Math.min(cellRange.endRow, this.rowIntersections.length - 1);
      const endCol = Math.min(cellRange.endColumn, this.colIntersections.length - 1);
      for (let row = cellRange.startRow; row <= endRow; row++) {
        for (let col = cellRange.startColumn; col <= endCol; col++) {
          const idKey = this.getIntersectionKey(row, col);
          if (idKey) {
            const originalCell = intersectionToCell.get(idKey);
            if (originalCell && originalCell.isLoaded) {
              const pivotCell = originalCell.copyWithNewPos(row, col);
              this.setCell(row, col, pivotCell);
            }
          }
        }
      }
    }
  }

  public createContext(): Promise<string | undefined> {
    this.contextIsLoading = true;
    return this.pivotService
      .createPivotContext(this.config)
      .then((_) => {
        this.contextId = this.pivotName;
        this.contextIsLoading = false;
        return this.contextId;
      })
      .catch(() => {
        this.contextIsLoading = false;
        return undefined;
      });
  }

  public getIntersectionKey(row: number, col: number) {
    const rowIntersect = this.rowIntersections[row];
    const colIntersect = this.colIntersections[col];

    if (isNil(rowIntersect) || isNil(colIntersect)) {
      return undefined;
    }
    let allIds: string[] = [];
    allIds = allIds.concat(rowIntersect.getFieldIds(), colIntersect.getFieldIds());
    return allIds.sort().join('>');
  }

  public setCell(r: number, c: number, cell: PivotCell) {
    this.rcIdToCell.set(r + '|' + c, cell);
  }

  public getCell(row: number, col: number) {
    if (row < 0 || col < 0) {
      // defensive check against negative grid calls
      return undefined;
    }
    const rcKey = row + '|' + col;
    let pivotCell = this.rcIdToCell.get(rcKey);
    if (!pivotCell) {
      pivotCell = new PivotCell({ row, col, metricId: '', currencyId: null, formatKey: '', coreVer: [] });
      this.setCell(row, col, pivotCell);
    }
    if (this.config.readOnly) {
      // TODO: push this logic somewhere else
      pivotCell.tags.push(CELL_TAG_DISABLED);
    }
    return pivotCell;
  }

  public getRow(row: number) {
    // helper function for just getting a row at a time
    const maxCol = this.getColCount();
    const cells: PivotCell[] = [];

    for (let index = 0; index < maxCol; index++) {
      const cell = this.getCell(row, index);
      if (cell) {
        cells.push(cell);
      }
    }
    return cells;
  }

  public inRange(r: number, c: number) {
    return 0 <= r && 0 <= c && r < this.getRowCount() && c < this.getColCount();
  }

  public getRowCount() {
    return this.rowIntersections.length;
  }

  public getColCount() {
    return this.colIntersections.length;
  }

  public getRowIntersection(r: number) {
    return this.rowIntersections[r];
  }

  public getColIntersection(c: number) {
    return this.colIntersections[c];
  }

  public getRowHeaderField(r: number, c: number) {
    const intersection = this.rowIntersections[r];
    if (!intersection) {
      return null;
    }
    return intersection.getField(c);
  }

  public getLastHeaderFieldInRow(r: number) {
    const intersection = this.rowIntersections[r];
    return intersection.getLastIndex();
  }

  public getColHeaderField(r: number, c: number) {
    const intersection = this.colIntersections[c];
    if (!intersection) {
      return null;
    }
    return intersection.getField(r);
  }

  public getLastHeaderFieldInCol(c: number) {
    const intersection = this.colIntersections[c];
    return intersection.getLastIndex();
  }

  public rowMatchesNext(r: number) {
    if (r >= this.rowIntersections.length - 1) {
      return false;
    }
    const intersect = this.rowIntersections[r];
    const field = intersect.getLineIndex();
    const prevIntersect = this.rowIntersections[r + 1];
    const prevField = prevIntersect.getLineIndex();
    return field.member.id === prevField.member.id;
  }

  public colMatchesNext(c: number) {
    if (c >= this.colIntersections.length - 1) {
      return false;
    }
    const intersect = this.colIntersections[c];
    const field = intersect.getLineIndex();
    const prevIntersect = this.colIntersections[c + 1];
    const prevField = prevIntersect.getLineIndex();
    return field.member.id === prevField.member.id;
  }

  public isCellLoaded(r: number, c: number) {
    const cell = this.getCell(r, c);
    return cell && cell.isLoaded;
  }

  public relaxCells(cells: PivotCell[]) {
    return this.toggleLockCells(cells, CELL_TAG_RELAXED);
  }

  // TODO: change name
  public lockCells(cells: PivotCell[]) {
    return this.toggleLockCells(cells, CELL_TAG_FROZEN);
  }

  // TODO: change name
  public constrainCells(cells: PivotCell[]) {
    return this.toggleLockCells(cells, CELL_TAG_LOCKED);
  }

  public forAllPresent(fn: (cell: PivotCell, r: number, c: number) => void) {
    this.rcIdToCell.forEach((cell) => {
      fn(cell, cell.row, cell.col);
    });
  }

  public toggleLockCells(cells: PivotCell[], lockState: CELL_LOCK_STATES): Promise<boolean> {
    if (this.contextIsLoading) {
      return Promise.resolve(false);
    }

    const resolveFn = (resolve: any) => {
      const requestedCells: { r: number; c: number }[] = [];
      for (const cell of cells) {
        cell.isLoaded = false;
        requestedCells.push({ r: cell.row, c: cell.col });
      }
      return this.pivotService.toggleLockableCells(this.config, requestedCells, lockState).then((isLocked) => {
        return resolve(isLocked);
      });
    };

    if (!this.contextId) {
      return this.createContext().then(() => {
        return new Promise<boolean>(resolveFn);
      });
    }
    return new Promise(resolveFn);
  }

  public updateCell(r: number, c: number, v: string) {
    const cell = this.getCell(r, c);
    // Since Javascript considers 0 a falsey value, we need to send zeros as strings
    // otherwise the request sends an empty payload resulting in error
    const number = v === '0' ? v : parseFloat(v);
    return this.pivotService.updateCell(this.config, r, c, number).then(() => {
      if (cell) {
        cell.value = v;
      }
    });
  }

  public updateCells(cells: PivotCell[]) {
    const updateCells = cells.map((cell) => {
      return {
        cell: {
          row: cell.row,
          col: cell.col,
        },
        value: parseFloat(cell.value!),
      };
    });

    return this.pivotService.updateCells(this.config, updateCells);
  }

  public loadMoreCells(range: CellRange): Promise<any> {
    if (this.currentReqCellRange.find((r) => isEqual(r, range))) {
      return Promise.resolve();
    } else {
      this.currentReqCellRange = this.currentReqCellRange.concat(range);
    }

    /* eslint-disable */
    const self = this;

    const resolveFn: () => Promise<any> = () => {
      const requestedCells: { r: number; c: number; }[] = [];
      for (let r = range.startRow; r <= range.endRow; r++) {
        for (let c = range.startColumn; c < range.endColumn; c++) {
          const cell = self.getCell(r, c);
          if (cell) {
            if (!cell.isLoaded || cell.needsReload) {
              requestedCells.push({ r, c });
            }
          }
        }
      }
      return self.pivotService
        .queryCells(self.config, requestedCells, self.getRowCount(), self.getColCount())
        .then((loadedCells) => {
          self.syncCells(loadedCells);
        })
        .finally(() => {
          this.currentReqCellRange = this.currentReqCellRange.filter((r) => !isEqual(r, range));
        });
    };

    if (!this.contextId) {
      return this.createContext().then((contextId) => {
        if (contextId) {
          return resolveFn();
        }
        return Promise.reject('Failed to Load Context');
      });
    }
    return resolveFn();
  }

  public syncCells(loadedCells: CellPosition[]) {
    for (const serverCell of loadedCells) {
      const pos = serverCell.position;
      const cell = this.getCell(pos.r, pos.c);
      if (cell) {
        cell.isLoaded = true;
        cell.needsReload = false;
        cell.value = serverCell.cell.value!; // does this null coercion cause issues downstream?
        cell.metricId = serverCell.cell.metricId;
        cell.tags = serverCell.cell.tags;
        cell.advisoryTags = serverCell.cell.advisoryTags;
        cell.currencyId = serverCell.cell.currencyId;
        cell.formatKey = serverCell.cell.formatKey;
        cell.coreVer = serverCell.cell.coreVer;
      }
    }
  }
}
