import { PersistMessage } from 'src/state/scope/Scope.types';
import {
  PublishEventPayload,
  WorkingSetContextPayload,
  WorkingSetReport,
} from 'src/state/workingSets/workingSets.slice';

export type EventSourceContext = 'mfp' | 'assortment';

//#region assortment

export enum AssortmentEventType {
  planQueue = 'plan_queue',
  worklist = 'worklist',
  exception = 'exception',
  fingerprint = 'fingerprint',
  undoChange = 'undo_change',
  add_to_assortment = 'add_to_assortment',
}

export const assortmentEventTypes: string[] = Object.values(AssortmentEventType);

interface BaseAsstServerEvent extends Event {
  data: string;
}

export interface AssortmentServerEvent extends BaseAsstServerEvent {
  type:
    | typeof AssortmentEventType.planQueue
    | typeof AssortmentEventType.worklist
    | typeof AssortmentEventType.exception
    | typeof AssortmentEventType.fingerprint
    | typeof AssortmentEventType.undoChange
    | typeof AssortmentEventType.add_to_assortment;
}

//#endregion

//#region mfp

export enum MfpEventType {
  context = 'context-async-state',
  persist = 'context-persist-state',
  report = 'report-async-state',
  publish = 'publish-event-status',
  actuals = 'actuals-event-status',
}

// filtering out actuals because that event type was not previously registered at all
export const mfpEventTypes: string[] = Object.values(MfpEventType).filter((t) => t !== MfpEventType.actuals);

interface ContextsEvent extends Event {
  type: MfpEventType.context;
  data: WorkingSetContextPayload[];
}
interface PersistEvent extends Event {
  type: MfpEventType.persist;
  data: PersistMessage;
}
interface ReportEvent extends Event {
  type: MfpEventType.report;
  data: WorkingSetReport[];
}

// TODO: remove when new publish event shape is deployed
/** @deprecated see PublishEvent instead */
export interface LegacyPublishEvent extends Event {
  type: MfpEventType.publish;
  data: { lastPublish: string };
}

export interface PublishEvent extends Event {
  type: MfpEventType.publish;
  data: PublishEventPayload;
}

interface ActualsEvent extends Event {
  type: MfpEventType.actuals;
  data: never;
}

export type MfpServerEvent =
  | ContextsEvent
  | PersistEvent
  | ReportEvent
  | PublishEvent
  | LegacyPublishEvent
  | ActualsEvent;

//#endregion
