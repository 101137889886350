import * as React from 'react';
import { modal } from './Configure.style';
import { Dialog, DialogContent, DialogTitle, Divider } from '@material-ui/core';
import { TransposedColumns } from 'src/services/configuration/codecs/viewdefns/general';
import { isNil } from 'lodash';

export interface Option {
  dataIndex: string;
  text: string;
  type?: string;
  renderer?: string;
  colDef?: string;
}

export interface OptionGroup {
  text: string;
  options: Option[];
}

export type ToggleAction = 'close' | 'apply';

export interface EnabledProps {
  enabled: true;
  isOpen: boolean;
  optionGroups: OptionGroup[];
  selections: Option[];
  instructions?: string;
  minimumSelections?: number;
  transposedColumns?: TransposedColumns;
  transposedSelections?: Option[];
  onToggleModal(action: ToggleAction): void;
  onReset(): void;
  selectionUpdate(selections: Option[], transposedSelections?: Option[]): void;
}

export interface DisabledProps {
  enabled: false;
}
export type ConfigureModalProps = EnabledProps | DisabledProps;

export interface Selection {
  dataIndex: string;
  selections: Option[];
  text: string;
}

export default class ConfigureModal extends React.Component<ConfigureModalProps> {
  constructor(props: ConfigureModalProps) {
    super(props);
    this.optionSelected = this.optionSelected.bind(this);
    this.optionClear = this.optionClear.bind(this);
  }

  optionSelected(groupIndex: number, selected: Option) {
    if (!this.props.enabled) {
      return;
    }
    const { selections, selectionUpdate } = this.props;
    const newSelections = [...selections];
    newSelections.splice(groupIndex, 1, selected);
    selectionUpdate(newSelections);
  }

  transposedOptionSelected(index: number, selected: Option) {
    if (!this.props.enabled) {
      return;
    }
    const { selections, transposedSelections, selectionUpdate } = this.props;

    if (isNil(transposedSelections)) return;
    const newSelections = [...transposedSelections];
    newSelections.splice(index, 1, selected);
    selectionUpdate(selections, newSelections);
  }

  optionClear(event: React.MouseEvent<HTMLSpanElement>) {
    if (!this.props.enabled) {
      return;
    }
    const { selections, selectionUpdate, minimumSelections } = this.props;
    event.stopPropagation();
    const newSelections = selections.slice(0, selections.length - 1);
    if (minimumSelections && newSelections.length < minimumSelections) {
      // some views don't allow less than a minumum number of aggBys
      return;
    }
    selectionUpdate(selections.slice(0, selections.length - 1));
  }

  render() {
    if (!this.props.enabled) {
      return null;
    }

    const { isOpen, optionGroups, onToggleModal, onReset, selections, instructions, transposedColumns, transposedSelections } = this.props;

    const className = modal + ' configure-modal';

    const aggLevelContent = optionGroups.map((optionGroup, groupIndex) => {
      const selectable = groupIndex <= selections.length;
      const optionGroupClasses = 'option-group' + (selectable ? ' selectable' : '');
      const selectionIds = new Set(selections.map((sel) => sel.dataIndex));

      return (
        <div className={optionGroupClasses} key={groupIndex} data-qa='row-level-config'>
          <div className="overlay" />
          <header>{optionGroup.text}</header>
          <ul>
            {optionGroup.options.map((option) => {
              const { text, dataIndex } = option;
              const selected = selections[groupIndex] && dataIndex === selections[groupIndex].dataIndex;
              const clearable = selected && (groupIndex === selections.length || groupIndex === selections.length - 1);
              const itemDisabled = selectionIds.has(dataIndex) && !selected;

              const itemClassName = 'option' + (selected ? ' selected' : '') + (itemDisabled ? ' disabled' : '');

              return (
                <li
                  className={itemClassName}
                  key={dataIndex}
                  onClick={(event) => {
                    if (selectable && !itemDisabled) {
                      this.optionSelected(groupIndex, option);
                    }

                    if (clearable) {
                      this.optionClear(event);
                    }
                  }}
                >
                  {text}
                </li>
              );
            })}
          </ul>
        </div>
      );
    });

    // Build timeLevelContent
    let timeLevelContent: JSX.Element[] | null = null;
    if (!isNil(transposedColumns) && !isNil(transposedSelections) && transposedSelections.length > 0) {

      const { topLevel, bottomLevel, topLevelTitle, bottomLevelTitle } = transposedColumns;
      const selectionIds = new Set(transposedSelections?.map((sel) => sel.dataIndex));

      timeLevelContent = transposedSelections.map((selec, index) => {
        const selectable = index <= selections.length;

        return (
          <div className={'option-group selectable'} key={index} data-qa="header-level-config">
            <div className="overlay" />
            <header>{index === 0 ? topLevelTitle : bottomLevelTitle}</header>
            <ul>
              {(index === 0 ? topLevel : bottomLevel).map((option, ind) => {
                const { text, dataIndex } = option;
                const selected = dataIndex === selec.dataIndex;
                const itemDisabled = selectionIds.has(dataIndex) && !selected;

                const itemClassName = 'option' + (selected ? ' selected' : '') + (itemDisabled ? ' disabled' : '');

                return (
                  <li
                    className={itemClassName}
                    key={ind}
                    onClick={(event) => {
                      if (selectable && !itemDisabled) {
                        this.transposedOptionSelected(index, option);
                      }
                    }}
                  >
                    {text}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      });
    }

    const mainContent = !isNil(timeLevelContent) ? (<>
      {timeLevelContent}
      <Divider orientation="vertical" flexItem variant="middle" />
      {aggLevelContent}
    </>) : aggLevelContent;

    return (
      <Dialog
        open={isOpen}
        onClose={() => onToggleModal('close')}
        classes={{ root: className }}
        fullWidth={true}
        scroll={'paper'}
        maxWidth={'xl'}
      >
        <DialogTitle>
          <span className="left-container">
            <i className="fas fa-cog icon" />
            Configure
          </span>
          <span className="right-container">
            <i className="far fa-times" onClick={() => onToggleModal('close')} />
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <p className="instructions">{instructions}</p>
          <main data-qa="configure-content-container">{mainContent}</main>
          <footer>
            <button onClick={() => onToggleModal('apply')} className="apply">
              <i className="far fa-check" />
              Apply
            </button>
            <button onClick={onReset} className="reset">
              <i className="fa fa-undo" />
              Reset
            </button>
          </footer>
        </DialogContent>
      </Dialog>
    );
  }
}
