import Axios from 'src/services/axios';
import { Indexable } from 'src/types/Primitive';
import { provideAppContext } from 'src/utils/Domain/Perspective';
import { isObject, cloneDeepWith, merge, get, omit, reject, isNil, nth, isUndefined } from 'lodash';
import { Option } from 'src/components/Configure/ConfigureModal';
import { DilineateOption } from 'src/components/Visualize/Visualize.utils';
import { FavoriteResponse, FavoriteResponseItem } from 'src/components/Subheader/Favorites/Favorites.types';
import { z, ZodTypeAny } from 'zod';
import { CustomInputAttributes } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import ServiceContainer from 'src/ServiceContainer';
import { toast } from 'react-toastify';
import { zChartTooltipConfig, zGeoTrendsMapDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { AppType } from 'src/services/configuration/codecs/bindings.types';
import { getAssortmentFavoriteByDefnId } from 'src/components/Subheader/Favorites/Favorites.client';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';

const C_REFS = '$configRefs';
const C_REF = '$configRef';

export const viewPath = '/api/uidefn/config';

export enum ViewDefnState {
  idle = 'idle',
  loading = 'loading',
  loaded = 'loaded',
}

export function isViewDefnLoaded(viewDefnState: ViewDefnState) {
  return viewDefnState === ViewDefnState.loaded;
}

export interface BasicTenantConfigViewItemComparator {
  type: string;
  options?: {
    format?: string;
  };
}

export interface BasicTenantConfigViewItem {
  dataIndex: string;
  chartType?: string;
  id?: string;
  comparator?: BasicTenantConfigViewItemComparator;
  defaultShownValues?: string[];
  text: string;
  dimension?: string;
  groupingKey?: string;
  formula?: string;
  atGroupLevel?: boolean;
  renderer?: string;
  style?: string;
  styleColor?: string;
  geoLocationKeys?: string[];
  modelId?: string;
  xtype?: string;
  imgHeight?: number;
  mask?: string;
  includePercentage?: boolean;
  visible?: boolean;
  pinned?: string | boolean;
  highlightText?: boolean;
  searchIndexes?: string[];
  menuDisabled?: boolean;
  aggFunc?: any;
  lockable?: boolean;
  draggable?: boolean;
  sortable?: boolean;
  resizable?: boolean;
  locked?: boolean;
  hidden?: boolean;
  isFooter?: boolean;
  isMainMetric?: boolean;
  editable?: boolean;
  filterType?: string;
  suppressMovable?: boolean;
  stylePaneOpenOnClick?: boolean;
  atSummaryLevel?: boolean;
  treeColumn?: boolean;
  defaultsOverride?: Option[];
  value?: any;
  selectedIndex?: number;
  left?: BasicTenantConfigViewItem; // used for visualize graph
  right?: BasicTenantConfigViewItem; // used for visualize graph
  color?: string; // used for visualize graph
  hideFromConfigurator?: boolean; // Don't show item in configurator;
  viewConfiguratorName?: string; //used for columns that don't have its own header name
  inputParams?: CustomInputAttributes; // used for controlling inputs, like max characters, whitelist, and return type
  /** defaults to false, set true to hide the column if the current column is the grouping column */
  hideGroupedColumn?: boolean;
  bubble?: zChartTooltipConfig;
  version?: number;
  sortIndex?: string; // use for TD summary / simpler chart to sort data
  arrayOrderDataIndex?: string; // used for arrayEditors in `ConfigurabelGrid`
}
export interface TenantConfigViewItem extends BasicTenantConfigViewItem {
  collapsed?: boolean;
  columns?: TenantConfigViewItem[];
  view?: TenantConfigViewItem[];
  classes?: string[];
}

export interface MainViewConfig extends TenantConfigViewItem {
  info?: string;
  rowHeight?: number;
  groupRowHeight?: number;
  columnWidth?: number;
  stars?: string;
  image?: string;
  body?: string;
  title?: string;
  displayTitle?: string;
  minimumSelections?: number;
  keys?: {
    idProps: string;
  };
  showExcelBtn?: boolean;
  showGroupTitlesExcel: boolean;
  nameOverride?: boolean;
}

export interface GraphsOptions {
  dataIndex?: string;
  primary: TenantConfigViewItem[];
  secondary: TenantConfigViewItem[];
}

export interface GroupByDefnColumn {
  groupingKey: string;
  dataIndex: string;
  text: string;
  xtype?: string;
  dimension: string;
}

export interface TenantConfigViewData {
  id?: string;
  $id: string;
  model?: string;
  main?: MainViewConfig;
  default?: string | number;
  defaults?: Indexable;
  view: TenantConfigViewItem[];
  type?: string;
  views?: TenantConfigViewItem[];
  columns?: TenantConfigViewItem[];
  options?: number[];
  geoLocationKeys?: string[];
  assortmentModel?: string;
  title?: TenantConfigViewItem;
  searchIndexes?: string[];
  defaultsOverride?: Option[];
  enabled?: boolean;
  dilineate?: DilineateOption;
  graphs?: GraphsOptions;
  isDefault?: boolean;
  grid?: TenantConfigViewData;
  adornments?: AdornmentType[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [C_REFS]?: { [s: string]: Record<string, any> };
  version?: number;
}

export interface GeoTrendsConfigData extends z.infer<typeof zGeoTrendsMapDefn> {}

export interface TenantConfigModelItem {
  name: string;
  type: string;
}
export type TenantConfigModelData = TenantConfigModelItem[];

export const defaultViewDefnData = {
  viewDefn: {
    id: '',
    $id: '',
    view: [],
  },
};
export const defaultModelDefnData = {
  modelDefn: [],
};

export interface TenantConfigQuery {
  defnId: string;
  appName?: AppType;
  validationSchema?: ZodTypeAny;
}

/** @deprecated See `ViewDefnQuery` instead, this will be removed when updated favorites fully implemented */
export interface TenantViewDefnQuery {
  defnIds: (string | null)[];
  appName?: AppType;
  favoritesDefn?: string;
  validationSchemas?: ZodTypeAny[];
}

interface ViewDefnQuery {
  defnIds: string[];
  favoritesDefn: string;
  validationSchemas?: ZodTypeAny[];
}

interface ViewDefnsWithFavorites<ViewDefn = TenantConfigViewData> {
  viewDefns: ViewDefn[];
  favorites: FavoriteResponse[];
}

export interface TenantConfigClient {
  getTenantViewDefns<T = TenantConfigViewData>(_query: TenantViewDefnQuery): Promise<T[]>;
  /** @deprecated See getViewDefnsWithFavorites instead which properly types the response */
  getTenantViewDefnsWithFavorites<T = TenantConfigViewData>(_query: TenantViewDefnQuery): Promise<T[]>;
  getViewDefnsWithFavorites<T>(query: ViewDefnQuery): Promise<ViewDefnsWithFavorites<T>>;
  getTenantViewDefn<T>(_query: TenantConfigQuery): Promise<T>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mergeConfigRefs = (vDefn: Record<string, any>) => {
  const refObj = get(vDefn, C_REFS);
  const viewDefn = omit(vDefn, [C_REFS]);
  // Goes through every key/value pair in an object, including arrays deeply
  return cloneDeepWith(viewDefn, (val: unknown) => {
    const objRefString = get(val, C_REF, '');
    const refObjMergable = get(refObj, objRefString);
    if (isObject(refObjMergable)) {
      return merge(val, refObjMergable);
    }
    return;
  });
};

function validateViewDefn<T>(defnId: string, schema: ZodTypeAny, input: T): T | undefined {
  try {
    if (input !== undefined && typeof input === 'string') {
      JSON.parse(input); // If this line throws a SyntaxError, input is not valid JSON
    }
    // If no error is thrown, proceed to validate against the schema
    const validated = schema.parse(input);
    return validated;
  } catch (error) {
    if (error instanceof SyntaxError) {
      // If parsing as JSON fails due to syntax error, throw custom error indicating invalid JSON
      toast.error("An error occurred while validating the view's configuration");
      ServiceContainer.loggingService.error(
        `Config file ${defnId} downloaded but\nis invalid JSON and could not be used`
      );
      // error.defnId = defnId;
      error.message = `Config file ${defnId} downloaded but is invalid JSON and could not be used`;
      throw error;
    } else if (error instanceof z.ZodError) {
      // If validation fails, report an error and rethrow the error
      toast.error("An error occurred while validating the view's configuration");
      ServiceContainer.loggingService.error(`Error validating: ${defnId}`);
      throw error;
    } else {
      // For other types of errors, rethrow the error
      throw error;
    }
  }
}

/** @deprecated see getAssortmentFavoriteByDefnId, this will be removed after favorites improvements */
async function getFavoritesList(favId: string): Promise<FavoriteResponseItem[]> {
  return provideAppContext(({ appName }) => {
    return Axios.get(`/api/favorites/by-path/${favId}?appName=${appName}`).then((resp) => {
      return resp.data.data;
    });
  });
}

async function getTenantViewConfig<T>(query: TenantConfigQuery): Promise<T> {
  // TODO: This can fail if we send the request between the server fingerprint update and receiving the new fingerprint over the event bus
  return provideAppContext(({ appName, fingerprint }) => {
    const url: string = reject([viewPath, fingerprint, appName, query.defnId], isNil).join('/');
    return Axios.get(url).then((resp) => {
      const validatedViewDefn = isNil(query.validationSchema)
        ? resp.data
        : validateViewDefn<z.infer<typeof query.validationSchema>>(query.defnId, query.validationSchema, resp.data);

      return {
        ...mergeConfigRefs(validatedViewDefn),
        $id: query.defnId,
      };
    });
  });
}

async function getTenantViewDefns(query: TenantViewDefnQuery): Promise<any[]> {
  const queries = query.defnIds.map((defnId, index) => {
    if (defnId == null) {
      return null;
    }
    return getTenantViewConfig({
      defnId: defnId,
      validationSchema: nth(query.validationSchemas, index),
    });
  });

  return Promise.all(queries);
}

/**
 * @deprecated see getViewDefnsWithFavorites instead, this is being used for the legacy favorites implementation and will be removed
 * @param query
 * @param favoriteDefn
 * @param validationSchemas an array of zod validators, need to be in the same array index as the defnId it matches in query.defnIds
 * @returns
 */
async function getTenantViewDefnsWithFavorites(
  query: TenantViewDefnQuery,
  favoriteDefn: string,
  validationSchemas: ZodTypeAny[]
): Promise<any[]> {
  const queries = query.defnIds.map((defnId, index) => {
    if (defnId == null) {
      return null;
    }
    return getTenantViewConfig({
      defnId: defnId,
      validationSchema: nth(validationSchemas, index),
    });
  });

  queries.push(getFavoritesList(favoriteDefn) as Promise<any>);
  return Promise.all(queries);
}

async function getViewDefnsWithFavorites<T>(
  query: ViewDefnQuery,
  favoriteDefn: string,
  validationSchemas: ZodTypeAny[]
): Promise<ViewDefnsWithFavorites<T>> {
  const viewDefnQueries = query.defnIds.map((defnId, index) =>
    isNil(defnId)
      ? Promise.reject()
      : getTenantViewConfig<T>({
          defnId: defnId,
          validationSchema: nth(validationSchemas, index),
        })
  );

  const favoriteQuery = getAssortmentFavoriteByDefnId(favoriteDefn);
  const queries = [...viewDefnQueries, favoriteQuery];

  const responses = await Promise.all(queries);
  return {
    viewDefns: responses.slice(0, -1) as T[], // align types up to last element
    favorites: responses.slice(-1)[0] as FavoriteResponse[], // last element
  };
}

async function getTenantViewDefn<T>(query: TenantConfigQuery): Promise<T> {
  return getTenantViewConfig({
    defnId: query.defnId,
    appName: query.appName,
    validationSchema: query.validationSchema,
  });
}

export function makeTenantConfigClient(): TenantConfigClient {
  return {
    getTenantViewDefns(query: TenantViewDefnQuery) {
      return getTenantViewDefns(query);
    },
    getTenantViewDefnsWithFavorites(query: TenantViewDefnQuery) {
      return getTenantViewDefnsWithFavorites(
        query,
        query.favoritesDefn || query.defnIds[0]!,
        query.validationSchemas || []
      );
    },
    getViewDefnsWithFavorites(query: ViewDefnQuery) {
      return getViewDefnsWithFavorites(query, query.favoritesDefn, query.validationSchemas || []);
    },
    getTenantViewDefn(query: TenantConfigQuery) {
      return getTenantViewDefn(query);
    },
  };
}
