import { ofType } from 'redux-observable';
import { Observable, concatMap, filter, of } from 'rxjs';
import { AppEpic, AppThunk } from 'src/store';
import { getFilterOptions, refreshFilterSelections } from '../Headerbar/Headerbar.container';
import { setRightContainerPayload } from '../RightContainer/RightContainer.slice';
import { RightContainerPayloadType } from '../RightContainer/RightContainer';
import { receiveScope, receiveScopeRefreshTrigger } from '../AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { getFilters } from './FilterPanel.container';

export const handleFetchFilters: AppEpic = (action$): Observable<AppThunk> => {
  return action$.pipe(
    ofType(
      setRightContainerPayload.type,
    ),
    filter((r) => {
      return r.payload.type === RightContainerPayloadType.Filter;
    }),
    concatMap(() => of(getFilterOptions(), refreshFilterSelections()))
  );
};

export const fetchFilterSelectionsOnLoad: AppEpic = (action$): Observable<AppThunk> => {
  return action$.pipe(
    ofType(
      receiveScope.type,
      receiveScopeRefreshTrigger.type,
    ),
    // we need the full filter set in order to handle the high visiblity rendering
    concatMap(() => of(refreshFilterSelections(), getFilters()))
  );
}