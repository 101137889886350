import React from 'react';
import { classes } from 'typestyle';
import errorBoundaryStyles from 'src/components/ErrorBoundary/ErrorBoundary.styles';

export const NoSelectedCompanionItemOverlay = () => {
  return (
    <div className={errorBoundaryStyles.container}>
      <div className={errorBoundaryStyles.contentWrapper({ wrapperType: 'warning' })}>
        <section className={errorBoundaryStyles.textWrapper}>
          <div className={classes(errorBoundaryStyles.textSpacer, errorBoundaryStyles.textHeader, 'warning')}>
            {/* 
              Warning, before changing the text you will need to update the function `beforeEachAsstText` 
              text selector for `invalidWorklist` promise in e2e-test/src/utils/general.ts 
            */}
            No Data
          </div>
          <div className={classes(errorBoundaryStyles.textSpacer, errorBoundaryStyles.textItem)}>
            There is no selection in the companion list.
          </div>
          <div className={classes(errorBoundaryStyles.subTextItem, errorBoundaryStyles.lightText)}>
            If this is unexpected, please contact support.
          </div>
        </section>
      </div>
    </div>
  );
};
