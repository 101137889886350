import * as React from 'react';
import { style } from 'typestyle';

function chunkArray<T>(items: T[], size: number): T[][] {
  const chunks: T[][] = [];
  for (let i = 0; i < items.length; i += size) {
    chunks.push(items.slice(i, i + size));
  }
  return chunks;
}

export const styles = {
  container: style({
    display: 'flex',
    flexDirection: 'column',
    // marginRight: '8px',
  }),
  headerContainer: style({
    width: '100%',
    textAlign: 'center',
    fontSize: '0.75rem',
    marginBottom: '4px',
  }),
  mainContent: style({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }),
  valueContainer: style({
    display: 'flex',
    alignItems: 'center',
  }),
  arrowContainer: style({
    textAlign: 'center',
    flexShrink: 0,
    fontSize: '0.75rem',
    marginRight: '4px',
  }),
  valueTextContainer: style({
    textAlign: 'center',
    flexGrow: 1,
  }),
  arrowUp: style({
    display: 'inline-block',
    borderLeft: 'solid 6px transparent',
    borderRight: 'solid 6px transparent',
    borderBottom: 'solid 8px #318310',
    position: 'relative',
    top: '15%',
  }),
  arrowDown: style({
    display: 'inline-block',
    borderLeft: 'solid 6px transparent',
    borderRight: 'solid 6px transparent',
    borderTop: 'solid 8px #c20c09',
    position: 'relative',
    top: '15%',
  }),
  childMetricsContainer: style({
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '8px',
    gap: '1rem',
  }),
  childMetricsColumn: style({
    display: 'flex',
    flexDirection: 'column',
  }),
  childMetricRow: style({
    fontSize: '0.65rem',
    color: '#555',
    lineHeight: '1.2em',
    marginBottom: '2px',
  }),
};

export enum ARROWDIRECTIONS {
  UP = 'UP',
  DOWN = 'DOWN',
}

export interface ArrowValueRendererChildMetric {
  label: string;
  value: string;
}

export interface ArrowValueRendererProps {
  header: string;
  value: string;
  arrowDirection: ARROWDIRECTIONS;
  childMetrics?: ArrowValueRendererChildMetric[];
  // FIXME: remove those that are for parent filtering only via Omit/Pick
  group?: string;
  hidden?: boolean;
  selected?: boolean;
}

export default class ArrowValueRenderer extends React.Component<ArrowValueRendererProps> {
  // Helper that groups child metrics in sets of up to 3
  renderChildMetrics() {
    const { childMetrics } = this.props;
    if (!childMetrics || childMetrics.length === 0) return null;

    // group them into sets of up to 3
    const chunked = chunkArray(childMetrics, 3);

    return (
      <div className={styles.childMetricsContainer}>
        {chunked.map((group, colIndex) => (
          <div className={styles.childMetricsColumn} key={colIndex}>
            {group.map((metric, rowIndex) => (
              <div key={rowIndex} className={styles.childMetricRow}>
                {metric.label}: {metric.value}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { header, value, arrowDirection } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.headerContainer}>{header}</div>
        <div className={styles.mainContent}>
          <div className={styles.valueContainer}>
            <div className={styles.arrowContainer}>
              <div className={arrowDirection === ARROWDIRECTIONS.UP ? styles.arrowUp : styles.arrowDown} />
            </div>
            <div className={styles.valueTextContainer}>
              <p>{value}</p>
            </div>
          </div>
          {this.renderChildMetrics()}
        </div>
      </div>
    );
  }
}
