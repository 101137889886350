import * as React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { concat, filter, forEach, head, intersectionBy, isEmpty, isNil, mapValues, pick, unionBy, uniq } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ServiceContainer from 'src/ServiceContainer';
import { modal } from 'src/components/Configure/Configure.style';
import { ToggleAction } from 'src/components/Configure/ConfigureModal';
import { GranularEditPayloadItem } from 'src/dao/pivotClient';
import { getValidValues } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.client';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { muiTheme } from 'src/utils/Style/Theme';
import {
  ConfigurableGridValuePostButton,
  ConfigurableGridMultiSelectPostButton,
} from 'src/services/configuration/codecs/viewdefns/general';
import { getUrl, processApiParams } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { z } from 'zod';
import { generateCoordinateValues } from './ConfigurableGrid.utils';
import { AppType } from 'src/services/configuration/codecs/bindings.types';
import {
  searchInputContainerStyle,
  tableStyle,
  styles,
  selectedTextContainer,
  tableContentContainer,
} from './ActionModal.style';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Skeleton } from '@material-ui/lab';
export interface ActionModalProps {
  isOpen: boolean;
  // config
  onToggleModal: (action: ToggleAction) => void;
  selectedItems: Record<string, unknown>[];
  config?: ConfigurableGridValuePostButton | ConfigurableGridMultiSelectPostButton;
}
export interface Option {
  value: string | number;
  label: string;
}
const LoadingSkeletons = () => {
  return (
    <React.Fragment>
      <Skeleton animation="wave" variant="text" width="100%" />
    </React.Fragment>
  );
};

type DataState = 'loading' | 'loaded' | 'error';
interface ActionSelectTable {
  multiSelect: boolean;
  selections: Option[];
  setSelections: React.Dispatch<React.SetStateAction<Option[]>>;
  dataState: DataState;
  setDataState: React.Dispatch<React.SetStateAction<DataState>>;
  dataApi: ConfigurableGridMultiSelectPostButton['params']['dataApi'];
  selectedItems: Record<string, unknown>[]; // used for populating dataApi params
}
const ActionSelectTable = ({
  multiSelect,
  selections,
  setSelections,
  dataState,
  setDataState,
  dataApi,
  selectedItems,
}: ActionSelectTable) => {
  const [dropdownData, setDropdownData] = useState<Option[]>([]);
  const isError = dataState === 'error';
  const isDataLoading = dataState === 'loading';
  // const [isDataLoaded, setDataloaded] = useState<boolean>(false);
  // const [isError, setError] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>();
  const [selectAll, setSelectAll] = useState<boolean>(false);
  useEffect(() => {
    const firstItem = head(selectedItems);
    if (isNil(firstItem) || isNil(dataApi)) {
      return;
    }
    setDataState('loading');
    // setDataloaded(false);
    // setError(false);
    const fetchDropdownData = async () => {
      try {
        if (dataApi.isListData) {
          const processedParamsArray = selectedItems.map((item) => processApiParams(dataApi, item).params);

          const joinedParams = mapValues(
            processedParamsArray.reduce(
              (acc, item) => {
                forEach(item, (value, key) => {
                  acc[key] = acc[key] ?? [];
                  acc[key].push(value);
                });
                return acc;
              },
              {} as Record<string, unknown[]>
            ),
            (value) => uniq(value).join(',')
          );

          const res = await ServiceContainer.pivotService.listData(dataApi.defnId, AppType.Assortment, {
            ...dataApi.params,
            ...joinedParams,
          });

          // setDataloaded(true);
          if (isEmpty(res.flat)) {
            throw new Error('No values were returned.');
          }
          setDataState('loaded');
          setDropdownData(res.flat as unknown as Option[]);
        } else {
          const processedParams = processApiParams(dataApi, firstItem);
          const values = await getValidValues(getUrl(processedParams));
          if (isEmpty(values)) {
            throw new Error('No values were returned.');
          }
          setDropdownData(
            values.map((v: Option) => ({
              value: v.value,
              label: v.label,
            }))
          );
          // setDataloaded(true);
          setDataState('loaded');
        }
      } catch (_err) {
        setDataState('error');
      }
    };
    fetchDropdownData();
  }, [dataApi, selectedItems, setDataState]);
  const filteredDropdownData = useMemo(() => {
    if (isNil(searchValue) || isEmpty(searchValue)) {
      return dropdownData;
    }
    const searchTerms = searchValue
      .toLowerCase()
      .split(';')
      .filter((term) => !isEmpty(term.trim()));

    return dropdownData.filter((i) =>
      searchTerms.some(
        (term) => i.label.toLowerCase().indexOf(term.trim()) >= 0 || (i.value as string).indexOf(term.trim()) >= 0
      )
    );
  }, [searchValue, dropdownData]);
  useEffect(() => {
    if (filteredDropdownData.length > 0 && selections.length > 0) {
      const allFilteredSelected =
        intersectionBy(filteredDropdownData, selections, (o) => o.value).length == filteredDropdownData.length;
      setSelectAll(allFilteredSelected);
    } else {
      setSelectAll(false);
    }
  }, [selections, filteredDropdownData]);
  const selectAllFiltered = useCallback(() => {
    setSelections(unionBy(filteredDropdownData, selections, (o) => o.value));
  }, [selections, filteredDropdownData, setSelections]);
  return (
    <MuiThemeProvider theme={muiTheme}>
      <div className={searchInputContainerStyle}>
        <input
          type="text"
          placeholder="Search groups..."
          aria-label="Search groups"
          onChange={(ev) => setSearchValue(ev.currentTarget.value)}
          defaultValue={''}
        />
        {multiSelect ? (
          <button
            className="select-all-btn"
            onClick={() => {
              selectAllFiltered();
            }}
            disabled={selectAll}
          >
            Select All
          </button>
        ) : null}
      </div>
      <div className={selectedTextContainer}>
        <Tooltip title={'Deselect All'}>
          <HighlightOffIcon
            onClick={() => {
              setSelections([]);
            }}
            className={`${styles.removeAllButton} ${selections.length === 0 ? 'disabled' : ''}`}
          />
        </Tooltip>
        Selected: {selections.length}
      </div>
      <div className={tableContentContainer}>
        <Table stickyHeader={true}>
          <TableHead className={tableStyle}>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(() => {
              if (isError) {
                return <TableCell colSpan={2}>Failed to fetch item data</TableCell>;
              }

              if (isDataLoading) {
                return Array(4)
                  .fill(null)
                  .map((_, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <LoadingSkeletons />
                      </TableCell>
                      <TableCell>
                        <LoadingSkeletons />
                      </TableCell>
                    </TableRow>
                  ));
              }

              if (isEmpty(dropdownData)) {
                return <TableCell colSpan={2}>No Data</TableCell>;
              }

              return filteredDropdownData.map((item) => {
                const { label: name, value } = item;
                const isSelected = selections.some((s) => s.value === value);
                const rowClass = isSelected ? styles.selected : styles.unselected;

                return (
                  <TableRow
                    key={value}
                    className={rowClass}
                    onClick={() => {
                      setSelections((curSels) => {
                        if (isSelected) {
                          return filter(curSels, (s) => s.value !== value);
                        } else if (multiSelect) {
                          return concat(curSels, item);
                        } else {
                          return [item];
                        }
                      });
                    }}
                  >
                    <TableCell>{value}</TableCell>
                    <TableCell>{name}</TableCell>
                  </TableRow>
                );
              });
            })()}
          </TableBody>
        </Table>
      </div>
    </MuiThemeProvider>
  );
};

export const ActionModal = ({ isOpen, onToggleModal, selectedItems, config }: ActionModalProps) => {
  const [selections, setSelections] = useState<Option[]>([]);
  const [dataState, setDataState] = useState<DataState>('loading');

  const submitUpdate = useCallback(async () => {
    if (isNil(config) || isEmpty(selectedItems)) {
      return; // how did we even get here?
    }
    const { keysToCopy, keyToUpdate, coordinateMap } = config.params;
    const payload: GranularEditPayloadItem[] = (selectedItems as BasicPivotItem[]).map((item) => {
      let valueToUpdate: unknown = null;
      switch (config?.type) {
        case 'multiSelectPost':
          valueToUpdate = selections.map((i) => i.value);
          break;
        case 'singleSelectPost':
          valueToUpdate = selections.length > 0 ? selections[0].value : null;
          break;
        case 'setValuePost':
          valueToUpdate = config.params.valueToSet;
          break;
      }
      return {
        coordinates: generateCoordinateValues(coordinateMap, item),
        ...pick(item, keysToCopy),
        [keyToUpdate]: valueToUpdate,
      };
    });
    await ServiceContainer.pivotService.granularEditSubmitData(payload);
  }, [config, selectedItems, selections]);

  const closeModal = useCallback(
    async (toggleType: ToggleAction) => {
      if (toggleType === 'apply') {
        // submit based on selections then close
        await submitUpdate();
      }
      onToggleModal(toggleType);
    },
    [onToggleModal, submitUpdate]
  );
  const className = modal + ' configure-modal';

  let mainContent = <></>;
  let disabled = false;
  switch (config?.type) {
    case 'setValuePost':
      mainContent = <div style={{ maxWidth: 400 }}>{config.params.description}</div>;
      break;
    case 'multiSelectPost':
    case 'singleSelectPost':
      const multiSelect = config.type === 'multiSelectPost';
      mainContent = (
        <ActionSelectTable
          multiSelect={multiSelect}
          selections={selections}
          setSelections={setSelections}
          dataState={dataState}
          setDataState={setDataState}
          dataApi={config.params.dataApi}
          selectedItems={selectedItems}
        />
      );
      const allowEmpty = config.params.allowEmptyOption ?? false;
      disabled = dataState !== 'loaded' || (!allowEmpty && isEmpty(selections));
      break;
  }
  return (
    <Dialog
      open={isOpen}
      className={className}
      onClose={() => closeModal('close')}
      fullWidth={true}
      scroll={'paper'}
      maxWidth={'xl'}
    >
      <DialogTitle>
        <span className="left-container">
          <i className="fas fa-cog icon" />
          {config?.text}
        </span>
        <span className="right-container">
          <i className="far fa-times" onClick={() => closeModal('close')} />
        </span>
      </DialogTitle>
      <DialogContent>
        {mainContent}
        <footer>
          <button onClick={() => closeModal('apply')} className="apply" disabled={disabled}>
            <i className="far fa-check" />
            Apply
          </button>
          <button onClick={() => closeModal('close')} className="reset">
            <i className="fas fa-ban" />
            Reset
          </button>
        </footer>
      </DialogContent>
    </Dialog>
  );
};
