import React from 'react';
import serviceContainer from 'src/ServiceContainer';
import { PrintProps, PrintSize } from 'src/components/higherOrder/Print/Print';
import styles from 'src/components/Headerbar/Headerbar.styles';
import { IconLink } from 'src/components/Headerbar/Links/IconLink';
import { DispatchProps } from './Headerbar.container';
import { StateProjection } from './Headerbar.selector';
import getWeekRangeText from 'src/utils/Functions/Description';
import { makeHeaderbarPlanSensitive, PlanSensitiveProps } from './AssortmentPlanSensitive';
import { isEmpty, omit, isNil, values, get, first, last } from 'lodash';
import RestoreSession from 'src/components/RestoreSession/RestoreSession.container';
import {
  getSessionPerspective,
  getSessionPage,
  getSessionScope,
} from 'src/components/RestoreSession/RestoreSession.utils';
import { FiltersLink } from 'src/components/Headerbar/Links/FiltersLink';
import { PrintLink } from 'src/components/Headerbar/Links/PrintLink';
import { RangeItem, Scope } from 'src/types/Scope';
import { PlanLink } from 'src/components/Headerbar/Links/PlanLink';
import { ReconcileLink } from 'src/components/Headerbar/Links/ReconcileLink';
import { ReduxSlice as FilterSlice } from 'src/components/FilterPanel/FilterPanel.container';

type HeaderbarProps = StateProjection & DispatchProps & PrintProps & PlanSensitiveProps;
type HeaderbarState = {
  planQueuePopover: boolean;
  printSizeOpen?: boolean;
  reconcileOpen?: boolean;
};

enum HighVisSepStyle {
  csv = 'csv',
  span = 'span',
}
interface HighVisFilter {
  description: string;
  values: string[];
  sepStyle: HighVisSepStyle;
}
interface ScopeTextProps {
  startKey: string;
  endKey: string;
  ranges: RangeItem[];
  scope: Scope;
  highVisFilters: HighVisFilter[];
}
/*
  Processes the filters into High vis groups ordered by presence in filterdefn
  Specifically, it looks for any selections related to `highVisibility` filters and
  builds the relevant object for rendering.
*/
const getHighVisFilters = (filters: FilterSlice): HighVisFilter[] => {
  const selections = filters.lastSelections;
  const filterDefns = filters.state.reduce((acc, group) => {
    const next = group.filters
      .filter((filter) => {
        return filter.highVisibility && selections.map((s) => s.filterDefnId).indexOf(filter.id) >= 0;
      })
      .map((filter) => {
        const sels = selections.filter((s) => s.filterDefnId === filter.id).map((s) => s.id);
        return {
          description: filter.name,
          values: sels,
          sepStyle: filter.inputType === 'rangepicker' ? 'span' : 'csv',
        } as HighVisFilter;
      });
    return acc.concat(next);
  }, [] as HighVisFilter[]);
  return filterDefns;
};
const ScopeText = (props: ScopeTextProps) => {
  const { startKey, endKey, ranges, scope } = props;
  const { productMemberName, locationMemberName } = scope;
  const begin = scope[startKey];
  const end = scope[endKey];
  const weekRangeText = getWeekRangeText(begin, end, ranges);
  // We separate the different sections into subspans to hopefully eventually
  // allow for flex-wrapping on small screens. Product and Location should always be grouped together though.
  return (
    <>
      <span>{`Product - ${productMemberName} | Location - ${locationMemberName}`}</span>
      <span>{`| ${weekRangeText}`}</span>
      {props.highVisFilters.map((f) => {
        let valueStr = '';
        if (f.sepStyle === HighVisSepStyle.span) {
          valueStr = `${first(f.values)} - ${last(f.values)}`;
        } else {
          valueStr = f.values.join(', ');
        }
        return <span className="high-visibility" key={f.description}>{`| ${f.description}: ${valueStr}`}</span>;
      })}
    </>
  );
};

export class Headerbar extends React.Component<HeaderbarProps, HeaderbarState> {
  popoverPlanQueue: React.RefObject<HTMLAnchorElement> = React.createRef();
  checkPlanIntervalId: NodeJS.Timer | null;

  constructor(props: HeaderbarProps) {
    super(props);
    this.state = {
      planQueuePopover: false,
      reconcileOpen: false,
    };
    this.checkPlanIntervalId = null;
  }

  handlePrintModeClick = () => serviceContainer.printService.setPrintMode(false);

  handlePrintClick = (size: PrintSize) => {
    serviceContainer.printService.openPrint(size.width, size.height);
  };

  toggleReconcileModal = () => {
    this.setState({
      reconcileOpen: !this.state.reconcileOpen,
    });
  };

  handleClickOnScope = () => {
    this.props.onToggleScopeSelector();
  };

  handleCartClick = () => {
    if (!isNil(this.props.assortmentCartLink)) {
      this.props.setActivePage(this.props.assortmentCartLink);
      window.location.hash = this.props.assortmentCartLink;
    }
  };

  

  render() {
    const {
      scope,
      filters,
      scopeRangesKey,
      scopeStartKey,
      scopeEndKey,
      isPrintMode,
      isViewPrintable,
      printSizes,
      cartButtonConfig,
      quickReconButtonConfig,
      addToAsstItems,
      askForRefresh,
      planTracker,
      onOpenPlanQueue,
      refreshPage,
    } = this.props;
    const hasLocalStorage = !isNil(getSessionPage()) && !isNil(getSessionPerspective());
    const scopeDefined = !values(omit(getSessionScope(), ['floorset'])).every(isEmpty);
    const currentScopeUndefined = values(omit(scope, ['floorset'])).every(isEmpty) && this.props.rangeList.length > 0;

    const showPlan = !isPrintMode;
    const showScope = !isPrintMode;
    const showFilters = !isPrintMode;
    const showReconcile = !isPrintMode && !isNil(quickReconButtonConfig);
    const showCart = !isPrintMode && !isNil(cartButtonConfig);
    const showRestore = hasLocalStorage && scopeDefined && currentScopeUndefined;
    return (
      <div className={`navbar navbar-expand-lg ${styles.headerbarStyle}`}>
        <span className="nav navbar-nav navbar-left" data-qa="HeaderbarLeftSide">
          {isPrintMode && (
            <IconLink
              id="ExitPrintMode"
              text="Exit Print Mode"
              iconClass="fal fa-step-backward"
              onClick={this.handlePrintModeClick}
              linkStyle={{ marginRight: 25, fontWeight: 'bold' }}
            />
          )}
          <ScopeText
            startKey={scopeStartKey}
            endKey={scopeEndKey}
            ranges={this.props[scopeRangesKey]}
            scope={scope}
            highVisFilters={getHighVisFilters(filters)}
          />
        </span>
        <ul className="nav navbar-nav ml-auto navbar-right" data-qa="HeaderbarRightSide">
          {showPlan && (
            <li>
              <PlanLink
                askForRefresh={askForRefresh}
                planItems={planTracker.planItems}
                addToAsstItems={addToAsstItems}
                onClick={onOpenPlanQueue}
                onRefresh={refreshPage}
              />
            </li>
          )}
          {showReconcile && (
            <li>
              <ReconcileLink
                title={get(quickReconButtonConfig, 'title', 'Quick Reconcile')}
                conf={quickReconButtonConfig}
                onOpenQuickReconcile={this.props.onOpenQuickReconcile}
                onCloseQuickReconcile={this.props.onCloseQuickReconcile}
                showQuickReconcile={this.props.showQuickReconcile}
              />
            </li>
          )}
          {showScope && (
            <li>
              <IconLink
                id="Scope"
                iconClass="far fa-crosshairs"
                text="Scope"
                onClick={this.handleClickOnScope}
                tooltipText={'Select Scope'}
              />
            </li>
          )}
          {showFilters && (
            <li>
              <FiltersLink filterSlice={filters} onFiltersOpen={this.props.onOpenFilters} />
            </li>
          )}
          <li>
            <PrintLink
              isViewPrintable={isViewPrintable}
              isPrintMode={isPrintMode}
              printSizes={printSizes}
              onClick={this.handlePrintClick}
            />
          </li>
          {showCart && (
            <li>
              <IconLink
                id="Cart"
                text={`${cartButtonConfig.text} ${this.props.cartCount > 0 ? `(${this.props.cartCount})` : ''}`}
                iconClass={cartButtonConfig.iconSrc}
                linkClass={'clickable'}
                onClick={this.handleCartClick}
                tooltipText={'View the items you wish to add to the assortment'}
              />
            </li>
          )}
        </ul>
        {/* @ts-ignore TODO fix router types */}
        {showRestore && <RestoreSession />}
      </div>
    );
  }
}

// @ts-ignore
// TODO: Headerbar is only a class component because of the use in the PlanSensitive HOC
// can really just remove the HOC and just make this a connected function component since the HOC is only used here
export default makeHeaderbarPlanSensitive(Headerbar);
