import React, { ReactElement } from 'react';
import {
  CellClickedEvent,
  CellClassParams,
  GridReadyEvent,
  GridApi,
  ColumnApi,
  ColDef,
  CellValueChangedEvent,
  GridOptions,
  RowNode,
} from '@ag-grid-community/core';

import { Overlay } from 'src/common-ui';
import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { DataGridProps } from 'src/common-ui/components/DataGrid/DataGrid';
import { find, isEmpty, isEqual, omit, isNil, noop } from 'lodash';
import { toast } from 'react-toastify';

import { ScrollTo } from 'src/common-ui/components/CompanionListView/CompanionListView';

import ConfigureModal, { ConfigureModalProps, Option } from 'src/components/Configure/ConfigureModal';
import Subheader from 'src/components/Subheader/Subheader.container';
import { GroupHeaderKey } from 'src/utils/Component/AgGrid/AgDataFormat';
import { SubheaderOwnProps, ConfigureOptions, SortByDirection } from 'src/components/Subheader/Subheader.types';
import { PrintProps } from 'src/components/higherOrder/Print/Print';
import { StylePaneable } from 'src/components/HistoryStylePane/HistoryStylePane.types';

import { ProjectStateProps } from './ListGridPair.selectors';
import { listPairStyle, gridActionsContainer } from './ListGridPair.styles';
import { StyleDetailsPopoverProps } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import {
  getLeafCount,
  companionDataParse,
  setDataGridDefaultSortColDef,
  disaggregateHeaderValue,
  maybeModifyColDefs,
} from './ListGridPair.utils';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { multiHeaderDecorate } from 'src/pages/AssortmentStrategy/TargetSetting/TargetSetting/NestedHeader';
import { ViewConfiguratorModalProps } from '../ViewConfiguratorModal/ViewConfiguratorModal';
import {
  getWorklistContextMenuItems,
  ContextMenuType,
  getWorklistMultiselectMenuItems,
  WorklistContextMenuAction,
} from 'src/components/WorklistContextMenu/WorklistContextMenu';
import { STYLE_ID } from 'src/utils/Domain/Constants';
import { FabHandlerInvocable, FabType } from 'src/components/higherOrder/withFab';
import { WorklistService } from 'src/services/Worklist/Worklist.service';
import ServiceContainer from 'src/ServiceContainer';
import { MasterDetailRenderer } from 'src/components/MasterDetailRenderer/MasterDetailRenderer';

import SplitButton from 'src/common-ui/components/SplitButton/SplitButton';
import { extraRowContainerStyles, gridContainerStyle } from '../ConfigurableGrid/ConfigurableGrid.styles';
import CheckboxCellRenderer from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/SafeCheckbox';
import CheckboxHeaderRenderer from '../CheckboxHeaderRenderer/CheckboxHeaderRenderer';
import { errorToLoggingPayload } from 'src/services/loggingService';
import { AdornmentsEnum } from 'src/services/configuration/codecs/viewdefns/literals';
import { AdornmentsGridRenderer } from 'src/components/Adornments/AdornmentsGridRenderer';
import CompanionList from '../CompanionList/CompanionList';
import { CompanionListProps } from '../CompanionList/CompanionList.types';
import { CompanionView } from 'src/services/configuration/codecs/viewdefns/general';

export type Props = ProjectStateProps & PrintProps & FunctionProps & StyleDetailsPopoverProps & FabHandlerInvocable;

export interface FunctionProps {
  onShowView(): void;
  onRefetchData(): void;
  onUpdate(): void;
  onDestroy(): void;
  openStylePane(stylePaneable: StylePaneable): void;
  onConfigAggregate?(aggBys: string[]): void;
  updateConfigureSelections?(selections: Option[]): void;
  onItemClicked(item: BasicPivotItem, eventTarget?: HTMLElement, activeTab?: string): void;
  onUpdateConfig(config: TenantConfigViewData): void;
}

export interface State {
  companionSortDirection?: SortByDirection;
  companionCollapsed: boolean;
  configureIsOpen: boolean;
  companionScrollTo?: ScrollTo;
  companionSortField?: string;
  configureLastSelected?: Option[];
  gridScrollTo?: ScrollTo;
  selectedId?: string;
  selectedItemCount: number;
  executingWorklistAction: boolean;
}

type ColDefWithChildren = ColDef & { children?: ColDefWithChildren[] };

export function omitColDefMutations(colDefs: ColDefWithChildren[]): ColDefWithChildren[] {
  return colDefs.map((col) => {
    const result = omit(col, ['headerComponentParams', 'cellStyle', 'comparator', 'editable']);
    if (Array.isArray(result.children)) {
      result.children = omitColDefMutations(result.children);
    }
    return result;
  });
}
class ListGridPair extends React.Component<Props, State> {
  initialState!: State;
  gridApi?: GridApi;
  columnApi?: ColumnApi;
  click: 'left' | 'right' = 'right';
  extendedDatGridRef: typeof ExtendedDataGrid | null = null;

  // this type is really (params: GetContextMenuItemsParams): (string | MenuItemDef)[];
  // but for whatever reason it didn't want to be assigned that below
  initialGetContextMenu: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      companionCollapsed: false,
      configureIsOpen: false,
      selectedItemCount: 0,
      executingWorklistAction: false,
    };
    this.handleChangeSort = this.handleChangeSort.bind(this);
  }

  componentDidMount() {
    this.props.onShowView();
  }

  componentDidUpdate(prevProps: Props, _prevState: State) {
    if (this.props.dataLoaded && prevProps.dataLoaded) {
      const prevColDefs = omitColDefMutations(prevProps.colDefs);
      const nextColDefs = omitColDefMutations(this.props.colDefs);
      if (
        !isEqual(nextColDefs, prevColDefs) &&
        isEqual(prevProps.configureSelections, this.props.configureSelections)
      ) {
        this.updateColDefs();
      }
    }

    if (this.props.dataLoaded && prevProps.dataLoaded && !isEqual(prevProps.favoritesList, this.props.favoritesList)) {
      const activeFavorite = this.props.favoritesList.find((x) => x.active === true);
      if (activeFavorite && activeFavorite.jsonBlob && activeFavorite.jsonBlob.companionData) {
        const compData = activeFavorite.jsonBlob.companionData;
        this.setState({
          companionSortField: compData.companionSortField,
          companionSortDirection: compData.companionSortDirection,
          companionCollapsed: compData.companionCollapsed,
        });
      } else {
        this.setState({
          companionSortField: this.props.defaultCompanionSortField.dataIndex,
          companionSortDirection: 'desc',
          companionCollapsed: false,
        });
      }
    }

    // This is used when grid view loads inside a WorkflowContainer instance, when the levelBy selected member changes
    if (this.props.dataLoaded && prevProps.dataLoaded && !isEqual(prevProps.topMembers, this.props.topMembers)) {
      this.props.onRefetchData();
    }
  }

  handleChangeSort() {
    if (this.props.dataLoaded && this.props.sortBy.refreshOnChange) {
      this.props.onRefetchData();
    }
  }

  onFabClick = () => {
    switch (this.props.fab?.fabType) {
      case FabType.worklist:
        this.addAllToWorklist();
        break;
      default:
        break;
    }
  };

  addAllToWorklist = async () => {
    if (!this.props.dataLoaded) {
      return;
    }

    const worklistService = WorklistService();
    const { data, identityField } = this.props;
    const itemIds = data.map((item) => item[identityField]);

    try {
      await worklistService.addItemsToWorklist(itemIds);
      toast.info('Item Successfully added to worklist');
    } catch (error) {
      const msg = 'An error occured adding an item to the worklist';
      toast.error(msg);
      ServiceContainer.loggingService.error(msg, errorToLoggingPayload(error));
    }
  };

  updateConfigureSelections = (selections: Option[]) => {
    if (this.props.updateConfigureSelections != null) {
      this.props.updateConfigureSelections(selections);
    }
  };
  // Specifically for the group configuring done within the view configurator
  submitNewConfigureSelections = (selections: Option[]) => {
    this.updateConfigureSelections(selections);
    if (this.props.onConfigAggregate) {
      const aggBys = selections.map((sel) => sel.dataIndex);
      this.props.onConfigAggregate(aggBys);
    }
    this.setState(
      {
        configureLastSelected: selections,
      },
      () => this.updateColDefs() // this is where the coldefs are refreshed
    );
  };

  updateColDefs() {
    if (this.props.dataLoaded !== true || this.gridApi == null) return;
    // We need to do this same process that we do in the render() so that the column headers remain accurate
    // after wrench menu changes
    const decoratedColDefs = multiHeaderDecorate(this.props.colDefs);
    const finalColDefs = this.props.allowWorklistFunctionality
      ? maybeModifyColDefs(decoratedColDefs, this.selectOne, this.selectAll)
      : decoratedColDefs;
    this.gridApi.setGridOption('columnDefs', finalColDefs);
  }

  componentWillUnmount() {
    if (this.props.onDestroy) {
      this.props.onDestroy();
    }
  }

  getColumnState = () => {
    if (this.columnApi) {
      return this.columnApi.getColumnState();
    }
    return;
  };

  setLoadingState = () => {
    this.setState({
      executingWorklistAction: true,
    });
  };

  getGridApi = () => {
    return this.gridApi;
  };

  deselectAll = () => {
    if (isNil(this.gridApi)) {
      return;
    }

    this.gridApi.forEachNodeAfterFilter((node) => {
      node.data.exceptionselect = false;
    });

    this.gridApi.refreshCells();
    this.setState({
      selectedItemCount: 0,
      executingWorklistAction: false,
    });
  };

  selectOne = (node: RowNode, isChecked: boolean) => {
    if (isNil(this.gridApi) || isNil(this.columnApi)) {
      return;
    }

    const { selectedItemCount } = this.state;
    let nextCount = selectedItemCount;

    if (node.data[GroupHeaderKey]) {
      // select all children in group
      const itemsToUpdate: any[] = [];
      node.childrenAfterFilter?.forEach((child) => {
        child.data.exceptionselect = isChecked;
        itemsToUpdate.push(child.data);
      });

      // run ag-grid transaction, so each row value change doesn't trigger cellValueChanged handler
      this.gridApi.applyTransaction({ update: itemsToUpdate });
      nextCount = isChecked ? selectedItemCount + itemsToUpdate.length : selectedItemCount - itemsToUpdate.length;
    } else {
      nextCount = isChecked ? selectedItemCount + 1 : selectedItemCount - 1;
    }

    this.setState({
      selectedItemCount: nextCount,
    });
  };

  selectAll = (isChecked: boolean) => {
    if (isNil(this.gridApi)) {
      return;
    }

    let updatedItemCount = 0;
    this.gridApi.forEachNodeAfterFilter((node) => {
      updatedItemCount += Number(isChecked);
      node.data.exceptionselect = isChecked;
    });

    this.gridApi.refreshCells();
    this.setState({
      selectedItemCount: updatedItemCount,
    });
  };

  onUpdateConfig = (config: TenantConfigViewData) => {
    if (this.props.dataLoaded && config.isDefault) {
      this.setState({
        companionSortField: this.props.defaultCompanionSortField.dataIndex,
        companionSortDirection: 'desc',
        companionCollapsed: false,
      });
    }

    this.props.onUpdateConfig(config);
  };

  render() {
    let mainContent: ReactElement | null = null;
    let subheaderProps: SubheaderOwnProps = {
      title: this.props.title || '',
    };

    if (this.props.configLoaded) {
      const {
        dataLoaded,
        isPrintMode,
        data,
        uniqueItems,
        colDefs,
        masterDetailConfig,
        frameworkComponents,
        subheaderViewDefns,
        defaultCompanionSortField,
        sortOptions,
        stylePaneTriggerSet,
        treeColumnDefinition,
        defaultConfigureSelections = [],
        configureOptionGroups,
        configureInstructions,
        onConfigAggregate,
        subheaderSummary,
        rowHeight,
        companionDataLookup,
        identityField,
        onItemClicked,
        activeTab,
        originalDefaultSelections,
        adornments,
        allowWorklistFunctionality,
        favoritesList,
        hideTitle,
        showFlowStatus,
        viewDataState,
      } = this.props;

      const configureSelections =
        this.props.configureSelections != null ? this.props.configureSelections : defaultConfigureSelections;

      const activeFavorite = favoritesList.find((x) => x.active === true);
      let favoriteSortField, favoriteSortDirection: SortByDirection | undefined, favoriteCompIsCollapsed;
      if (activeFavorite && activeFavorite.jsonBlob && activeFavorite.jsonBlob.companionData) {
        if (activeFavorite.jsonBlob.companionData.companionSortField) {
          favoriteSortField = activeFavorite.jsonBlob.companionData.companionSortField;
        }
        favoriteSortDirection = activeFavorite.jsonBlob.companionData.companionSortDirection;
        favoriteCompIsCollapsed = activeFavorite.jsonBlob.companionData.companionCollapsed;
      }

      const {
        gridScrollTo,
        companionScrollTo,
        companionSortField = favoriteSortField || defaultCompanionSortField.dataIndex,
        configureIsOpen,
        configureLastSelected = configureSelections,
        companionSortDirection = favoriteSortDirection || 'desc',
      } = this.state;

      const configureOptions: ConfigureOptions = {
        type: 'enabled',
        onConfigureClick: () => {
          this.setState({
            configureIsOpen: true,
            configureLastSelected: configureSelections,
          });
        },
      };

      const viewConfigurator: ViewConfiguratorModalProps | undefined = this.props.configuratorViewDefn &&
        this.props.configuratorViewDefn.view &&
        this.props.unmodifiedViewDefn && {
          viewConfig: this.props.configuratorViewDefn,
          unmodifiedViewDefn: this.props.unmodifiedViewDefn,
          companionData: {
            companionSortDirection,
            companionCollapsed: this.state.companionCollapsed,
            companionSortField,
          },
          defaultCompanionData: {
            companionSortDirection: 'desc',
            companionCollapsed: false,
            companionSortField: defaultCompanionSortField.dataIndex,
          },
          updateConfig: this.onUpdateConfig,
          showPinCheckboxForGrid: true,
          getColumnApi: () => {
            if (this.columnApi) {
              return this.columnApi;
            }
            return;
          },
        };

      if (configureOptionGroups && viewConfigurator) {
        viewConfigurator.configureSelections = configureSelections;
        viewConfigurator.defaultConfigureSelections = originalDefaultSelections;
        viewConfigurator.updateConfiguration = this.submitNewConfigureSelections;
      }

      subheaderProps = {
        ...subheaderProps,
        showSearch: true,
        showFlowStatus,
        groupByDefn: subheaderViewDefns.groupBy,
        pareDownDefn: subheaderViewDefns.pareDown,
        summary: subheaderSummary,
        configureOptions: configureOptionGroups ? configureOptions : undefined,
        viewConfigurator: !isNil(viewConfigurator) ? viewConfigurator : undefined,
        downloadLink: this.props.downloadLink,
        viewDataState,
        onChangeSort: this.handleChangeSort,
        hideTitle,
        // TODO: need reference to the grid api here so the favorites can access the grid's sort/filter models
      };

      const companionData = companionDataParse(
        uniqueItems,
        companionDataLookup,
        companionSortDirection,
        companionSortField
      );

      const sortedColDefs = setDataGridDefaultSortColDef(
        colDefs,
        'desc',
        defaultCompanionSortField.dataIndex,
        masterDetailConfig,
        adornments,
        this.props.identityField
      );

      if (treeColumnDefinition) {
        treeColumnDefinition.valueGetter = (params) => {
          if (isNil(params.data)) return;
          const group = params.data.group;
          const last = group[group.length - 1];
          const leafCount = getLeafCount(params.data);
          const colDefGroup = find(colDefs, { headerName: 'Group' });
          let fieldValue = last;

          if (colDefGroup && colDefGroup.field && !params.data[GroupHeaderKey] && colDefGroup.field !== last) {
            fieldValue = params.data[colDefGroup.field];
          }

          return params.data[GroupHeaderKey] && leafCount ? `${fieldValue} (${leafCount})` : fieldValue;
        };

        // Added to prevent valueFormatters from other treecol defs from being added
        treeColumnDefinition.valueFormatter = undefined;

        treeColumnDefinition.cellRendererParams = {
          suppressCount: true,
        };
      }

      const boundWorklistContextMenuItems = adornments.includes(AdornmentsEnum.worklist)
        ? getWorklistContextMenuItems.bind(null, {
            identityField,
            parentIdentityField: STYLE_ID,
            type: ContextMenuType.grid,
            openStylePane: this.props.openStylePane,
          })
        : undefined;

      const masterDetailGridOptions: GridOptions | null = masterDetailConfig && {
        masterDetail: true,
        isRowMaster: (dataItem) => {
          // TODO: verify this works after upgrade
          // check to see if this is a grouping row or not
          return isNil(dataItem.children) || isEmpty(dataItem.children);
        },
        detailCellRenderer: 'masterDetail',
        detailCellRendererParams: {
          ...masterDetailConfig,
        },
        detailRowHeight: 420,
      };

      const columnDefs = !allowWorklistFunctionality
        ? sortedColDefs
        : maybeModifyColDefs(sortedColDefs, this.selectOne, this.selectAll);

      const gridOptions: DataGridProps = {
        data,
        frameworkComponents: {
          ...frameworkComponents,
          masterDetail: MasterDetailRenderer,
          gridCheckbox: CheckboxCellRenderer,
          gridHeaderCheckbox: CheckboxHeaderRenderer,
          adornmentsGridRenderer: AdornmentsGridRenderer,
        },
        isPrintMode,
        rowHeight,
        treeColumnDefinition,
        autoSizeOnReady: true,
        columnDefs,
        loaded: dataLoaded,
        scrollTo: gridScrollTo,
        refreshColumnsOnPropsChange: false,
        onGridReady: (event: GridReadyEvent) => {
          this.gridApi = event.api;
          this.columnApi = event.columnApi;
        },
        exportOptions: {
          fileName: this.props.title,
          showGroupTitlesExcel: this.props.unmodifiedViewDefn?.main?.showGroupTitlesExcel,
        },
        rowClassRules: {
          'header-row': (params: CellClassParams) => !isNil(params.data) && !isNil(params.data[GroupHeaderKey]),
        },
        onCellClicked: (event: CellClickedEvent) => {
          const columnId = event.column.getColId();

          if (allowWorklistFunctionality && columnId === 'exceptionselect') {
            // cell is from non-select column
            // clicks on this column should not select row in grid or companion view
            event.node.setSelected(false);
            return;
          }
          if (this.state.selectedId !== event.data[identityField]) {
            event.node.setSelected(false);
          } else {
            event.node.setSelected(true);
          }
          if (event && !event.data.$$GroupHeader) {
            if (
              event.colDef.field &&
              stylePaneTriggerSet.has(event.colDef.field) &&
              event.event &&
              event.event.target
            ) {
              onItemClicked(event.data, event.event.target as HTMLElement, activeTab);
            } else if (this.props.dataLoaded) {
              const identityValue = event.data[identityField];

              this.setState({
                companionScrollTo: {
                  eventId: Date.now(),
                  where: {
                    key: 'id',
                    value: identityValue,
                  },
                },
                selectedId: identityValue,
              });
            }
          }
        },
        // pass in worklist functionality via alternate context menu here
        // the function is called each time the context menu is opened
        getAlternateContextMenu: adornments.includes(AdornmentsEnum.worklist)
          ? boundWorklistContextMenuItems
          : undefined,
        extraAgGridProps: {
          onGridPreDestroyed: () => {
            // guarantee grid api references are not set
            this.gridApi = undefined;
            this.columnApi = undefined;
          },
          suppressScrollOnNewData: true, // makes the grid not reset scroll on new data
          suppressRowClickSelection: true,
          onCellValueChanged: (event: CellValueChangedEvent) => {
            const isExceptionSelectColumn = event.colDef.field === 'exceptionselect';
            if (event.data[GroupHeaderKey] && !isExceptionSelectColumn) {
              disaggregateHeaderValue(event);
            }
          },
          defaultColDef: {
            resizable: true,
            sortable: true,
            filter: true,
          },
          ...masterDetailGridOptions,
        },
      };

      const companionProps: CompanionListProps = {
        sortField: this.state.companionSortField || defaultCompanionSortField.dataIndex,
        config: {
          sortBy: { options: sortOptions, defaults: defaultCompanionSortField },
          itemMappings: { main: this.props.companionDataItemMapping as CompanionView['itemMappings']['main'] },
        },
        selectedItemId: this.state.selectedId ?? null,
        data: companionData,
        isDataloaded: this.props.dataLoaded,
        scrollTo: companionScrollTo,
        sortDirection: this.state.companionSortDirection || companionSortDirection,
        dataLookup: companionDataLookup,
        isCollapsed: favoriteCompIsCollapsed || this.state.companionCollapsed,
        onSortDirectionChange: (sortByDirection: SortByDirection) => {
          this.setState({
            companionSortDirection: sortByDirection,
            companionScrollTo: this.state.selectedId
              ? {
                  eventId: Date.now(),
                  where: {
                    key: 'id',
                    value: this.state.selectedId,
                  },
                }
              : undefined,
          });
        },
        onSortChange: (sortByDataIndex: string) => {
          this.setState({ companionSortField: sortByDataIndex });
          this.setState({
            companionSortField: sortByDataIndex,
            companionScrollTo: this.state.selectedId
              ? {
                  eventId: Date.now(),
                  where: {
                    key: 'id',
                    value: this.state.selectedId,
                  },
                }
              : undefined,
          });
        },
        renderSearchComponent: false,
        renderFilterComponent: false,
        onItemSelect: (identityValue) => {
          this.setState({
            gridScrollTo: {
              eventId: Date.now(),
              where: {
                key: identityField,
                value: identityValue,
              },
            },
            selectedId: identityValue,
          });
        },
      };

      let configureModalProps: ConfigureModalProps;

      if (configureOptionGroups) {
        configureModalProps = {
          enabled: true,
          isOpen: configureIsOpen,
          optionGroups: configureOptionGroups,
          selections: configureSelections,
          instructions: configureInstructions,
          onReset: () => {
            this.updateConfigureSelections(defaultConfigureSelections);
          },
          onToggleModal: (action) => {
            const isOpen = !configureIsOpen;
            const nextState: State = {
              ...this.state,
              configureIsOpen: isOpen,
            };

            switch (action) {
              case 'apply': {
                if (onConfigAggregate && !isEqual(configureSelections, this.state.configureLastSelected)) {
                  onConfigAggregate(configureSelections.map((sel) => sel.dataIndex));
                }
                nextState.configureLastSelected = configureSelections;
                break;
              }
              default:
                this.updateConfigureSelections(configureLastSelected);
            }

            this.setState(nextState);
          },
          selectionUpdate: (selections: Option[]) => {
            this.updateConfigureSelections(selections);
          },
        };
      } else {
        configureModalProps = { enabled: false };
      }

      const renderListView = !isPrintMode && !this.props.hideCompanion;
      const listView: JSX.Element | null = renderListView ? <CompanionList {...companionProps} /> : null;
      const menuItems = allowWorklistFunctionality
        ? getWorklistMultiselectMenuItems(identityField, this.getGridApi, {
            onPostAction: this.deselectAll,
            onPreAction: this.setLoadingState,
          })
        : [];
      mainContent = (
        <React.Fragment>
          <div className="data-container">
            {listView}
            <div className="content-container">
              <div className={extraRowContainerStyles}>
                {allowWorklistFunctionality && (
                  <div className={gridActionsContainer}>
                    <SplitButton
                      text={WorklistContextMenuAction.addSelected}
                      icon={menuItems[0].icon as string}
                      isLoading={this.state.executingWorklistAction}
                      isDisabled={this.state.selectedItemCount === 0}
                      onButtonClick={menuItems[0].action ?? noop}
                      menuItems={menuItems.slice(1)}
                    />
                  </div>
                )}
              </div>
              <div className={gridContainerStyle}>
                <ExtendedDataGrid {...gridOptions} />
              </div>
            </div>
          </div>
          <ConfigureModal {...configureModalProps} />
        </React.Fragment>
      );
    } else {
      subheaderProps = {
        ...subheaderProps,
        viewDataState: this.props.viewDataState,
        showSearch: true,
        groupByDefn: this.props.subheaderViewDefns.groupBy,
        pareDownDefn: this.props.subheaderViewDefns.pareDown,
      };
      mainContent = (
        <div className="unloaded-main-content-container">
          <Overlay type="loading" visible={true} />
        </div>
      );
    }

    return (
      <div className={listPairStyle}>
        <Subheader {...subheaderProps} />
        {mainContent}
      </div>
    );
  }
}
/** @deprecated  */
export default ListGridPair;
