import React from 'react';
import { isNil } from 'lodash';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { TopAttribute } from 'src/services/configuration/codecs/viewdefns/general';
import Renderer from 'src/utils/Domain/Renderer';
import styles from 'src/components/ConfigurableGrid/Macros/Macros.styles';

export function generateRollupText(data: BasicPivotItem | undefined, configuratorViewDefn: any) {
  if (isNil(data) || isNil(configuratorViewDefn)) {
    return [];
  }
  let rollupText: string[] = [];
  if (configuratorViewDefn.topAttributes) {
    rollupText = configuratorViewDefn.topAttributes.left.map((config: TopAttribute) => {
      const mask = config.mask;
      const renderer = Renderer[config.renderer];
      const styles = config.classes;
      // We do this to prevent screen from crashing when fields are missing from data
      try {
        const value =
          renderer && mask && styles
            ? Renderer[config.renderer](data, mask, styles)
            : renderer && mask
              ? Renderer[config.renderer](data, mask)
              : '';
        return value;
      } catch (e) {
        console.warn(`Error building template for left text. \n ${e}`); // eslint-disable-line no-console
        return '';
      }
    });
  }

  return rollupText;
}

interface RollupProps {
  config: unknown;
  topAttributes: BasicPivotItem | undefined;
}

export const Rollup = ({ config, topAttributes }: RollupProps) => {
  return (
    <div className={styles.rollupsContainer}>
      {generateRollupText(topAttributes, config).map((text: string) => {
        return <span key={text}>{text}</span>;
      })}
    </div>
  );
};
