import dayjs from 'dayjs';
import { Dispatch, AnyAction } from 'redux';
import { Observable, Subscription } from 'rxjs';
import { repeat, switchMap } from 'rxjs/operators';
import {
  WorkingSetReport,
  receivedWorkingSets,
  receivedReports,
  WorkingSetContext,
  setLastPublishTime,
  receivedPublish,
  receivedActualsUpdate,
} from 'src/state/workingSets/workingSets.slice';
import {
  EventSourceContext,
  LegacyPublishEvent,
  MfpEventType,
  MfpServerEvent,
  PublishEvent,
  mfpEventTypes,
} from 'src/services/ServerSentEvents/ServerSentEventSource.types';
import { updatePersistenceStatus } from 'src/state/scope/Scope.slice';
import { PersistMessage } from 'src/state/scope/Scope.types';
import { ServerSentEventSource } from 'src/services/ServerSentEvents/ServerSentEventSource';
import { ServerSentEventConnectionError } from 'src/services/ServerSentEvents/ServerSentEventErrors';
import { BaseEventSource } from 'src/services/ServerSentEvents/BaseEventSource';

export class MfpEventSource extends BaseEventSource {
  protected context: EventSourceContext = 'mfp';

  constructor(protected onErrorCallback: (error: ServerSentEventConnectionError) => void) {
    // nothing to do here, just need to gain access to onErrorCallback
    super(onErrorCallback);
  }

  protected streamListener = (dispatch: Dispatch<AnyAction>, message: Event): void => {
    // TODO fix this coercison nonsense
    const msg = message as MfpServerEvent; // This is the correct type, but the DOM lists it incorrectly
    // TODO replace the below with zod parsing for safety;
    const data = JSON.parse(msg.data as unknown as string) as MfpServerEvent['data'];

    switch (msg.type) {
      case MfpEventType.context: {
        const d = data as WorkingSetContext[];
        const parsedDates: WorkingSetContext[] = d.map((ws): WorkingSetContext => {
          return {
            ...ws,
            contextCreationTime: dayjs(ws.contextCreationTime),
          };
        });
        dispatch(receivedWorkingSets(parsedDates));
        break;
      }
      case MfpEventType.persist:
        dispatch(updatePersistenceStatus(data as PersistMessage));
        break;
      case MfpEventType.report:
        dispatch(receivedReports(data as WorkingSetReport[]));
        break;
      case MfpEventType.publish:
        // TODO: remove when new publish event shape is deployed
        if ((data as LegacyPublishEvent['data']).lastPublish) {
          dispatch(setLastPublishTime((data as LegacyPublishEvent['data']).lastPublish));
        } else {
          dispatch(receivedPublish(data as PublishEvent['data']));
        }
        break;
      case MfpEventType.actuals:
        dispatch(receivedActualsUpdate());
        break;
      default:
        break;
    }
  };

  protected eventSource = (accessToken: string): Observable<Event> => {
    return new Observable((observer) => {
      const eventSource = new ServerSentEventSource(this.context, observer, accessToken, mfpEventTypes);
      return () => eventSource.unsubscribe();
    });
  };

  public connect = (dispatch: Dispatch<AnyAction>, accessToken$: Observable<string>): Subscription => {
    return accessToken$
      .pipe(switchMap(this.createEventSource))
      .pipe(repeat())
      .subscribe((ev) => this.streamListener(dispatch, ev));
  };
}
