import { EventSourceContext } from './ServerSentEventSource.types';

export class ServerSentEventConnectionError extends Error {
  context: EventSourceContext;

  constructor(context: EventSourceContext) {
    const message = `Failed to receive message from ${context}-api event source after 5s.`;
    super(message);

    this.name = 'ServerSentEventConnectionError';
    this.context = context;
  }
}
