import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { AppState, AppThunkDispatch } from 'src/store';
import { Divider } from '@material-ui/core';
import { concat, findIndex, isNil } from 'lodash';

import { TrendSummary } from 'src/components/TrendSummary/TrendSummary';
import { TrendBubbles } from 'src/components/TrendBubbles/TrendBubbles';
import type {
  TrendSummaryConfig,
  TrendBubblesConfig,
  TrendDetailsGridConfig,
  TrendConfig,
} from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { zTrendConfig } from 'src/services/configuration/codecs/viewdefns/viewdefn';

import { TrendDetails } from 'src/components/TrendDetails/TrendDetails';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { isViewDefnLoaded } from 'src/dao/tenantConfigClient';
import { SubheaderViewDefns, updateTopMemberSelection } from 'src/components/Subheader/Subheader.slice';
import { requestTrendsConfig, receiveTrendsConfig, receiveError } from './QuickTrends.slice';
import container from 'src/ServiceContainer';
import { getUniqueDataFromCache, HashType, isDataLoaded } from 'src/services/pivotServiceCache';
import { Overlay } from 'src/common-ui';
import Subheader from 'src/components/Subheader/Subheader.container';
import { ViewDataState } from 'src/types/Domain';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { quickTrendsStyles as styles } from './QuickTrends.styles';
import { makelookBackPredicate } from 'src/utils/Pivot/Filter';
import { cascadingFilter } from 'src/utils/Tree/ObjectArrayTree';
import { getGroupBySelectedOptionProperty } from 'src/utils/Pivot/Sort';
import { SubheaderDropdownProps } from 'src/components/Subheader/SubheaderDropdown';
import { SelectorSubheaderDropdownProps } from 'src/components/ConfigurableGrid/ConfigurableGrid.selectors';
import { bindActionCreators } from 'redux';
import { HasTitle } from 'src/services/configuration/codecs/confdefnView';
import { QuickTrendsOwnProps } from 'src/services/configuration/codecs/ownProps';
import { getTopMemberDropdownProps } from 'src/components/Subheader/Subheader.selectors';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';

export interface QuickTrendsStateProps extends HasTitle {
  groupByDataIndex: string;
  loaded: boolean;
  trendsSummaryDataState: ViewDataState;
  subheaderViewDefns: SubheaderViewDefns;
  summaryConfig: TrendConfig | null;
  detailsConfig: TrendConfig | null;
  summaryData: BasicPivotItem[];
  gridData: BasicPivotItem[];
  isPrintMode: boolean;
  floorsetDropdownProps?: SelectorSubheaderDropdownProps;
  showLookBackPeriod: boolean;
  showFlowStatus: boolean;
}

export interface QuickTrendsDispatchProps {
  onShowView: () => void;
  updateTopMemberSelection: (s: string) => void;
}

export interface QuickTrendsProps extends QuickTrendsDispatchProps, QuickTrendsStateProps { }

function mapStateToProps(state: AppState, ownProps: QuickTrendsOwnProps): QuickTrendsStateProps {
  const { defns, title, showLookBackPeriod, showFlowStatus } = ownProps;
  const { viewDefnState, summaryViewDefn, detailsViewDefn, trendsSummaryDataState, trendsDetailsDataState } =
    state.pages.hindsighting.quickTrends;
  const { viewDefn } = state.worklist;
  const confLoaded = isViewDefnLoaded(viewDefnState);
  const summaryLoaded = isDataLoaded(trendsSummaryDataState);
  const groupByDataIndex = getGroupBySelectedOptionProperty(state.subheader.groupBy, 'dataIndex');
  const floorsetDropdownProps = viewDefn?.subheaderDropdowns
    ? getTopMemberDropdownProps(state, { topMember: viewDefn?.subheaderDropdowns[1] })
    : undefined;
  const summaryData = getUniqueDataFromCache(state.pages.hindsighting.quickTrends, HashType.trendsSummary)?.tree || [];
  const gridData = !isNil(ownProps.gridDataApi)
    ? getUniqueDataFromCache(state.pages.hindsighting.quickTrends, HashType.trendsDetails)?.tree || []
    : summaryData;
  const lookBackPredicate = makelookBackPredicate(state.subheader.lookBackPeriod);
  const gridDataLoaded = !isNil(ownProps.gridDataApi) ? isDataLoaded(trendsDetailsDataState) : summaryLoaded;

  return {
    loaded: confLoaded && summaryLoaded && gridDataLoaded,
    groupByDataIndex,
    floorsetDropdownProps,
    summaryData: cascadingFilter(summaryData, lookBackPredicate),
    gridData: cascadingFilter(gridData, lookBackPredicate),
    summaryConfig: summaryViewDefn,
    detailsConfig: detailsViewDefn,
    trendsSummaryDataState,
    subheaderViewDefns: defns.subheader,
    isPrintMode: state.print.isPrintMode,
    title,
    showLookBackPeriod,
    showFlowStatus,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch, { defns }: QuickTrendsOwnProps): QuickTrendsDispatchProps {
  return {
    ...bindActionCreators({ updateTopMemberSelection }, dispatch),
    onShowView: async () => {
      dispatch(requestTrendsConfig());
      try {
        const [summaryViewDefn, detailsViewDefn] = await container.tenantConfigClient.getTenantViewDefns<
          Array<TrendConfig>
        >({
          defnIds: defns.view,
          appName: ASSORTMENT,
          validationSchemas: [zTrendConfig, zTrendConfig],
        });

        dispatch(
          receiveTrendsConfig({
            summaryViewDefn: summaryViewDefn as unknown as TrendConfig,
            detailsViewDefn: detailsViewDefn as unknown as TrendConfig,
          })
        );
      } catch (error) {
        dispatch(
          receiveError({
            type: ComponentErrorType.config,
            message: (error as Error)?.message,
            name: ConfDefnComponentType.quickTrends,
            issues: error,
          })
        );
      }
    },
  };
}

const trendTypeToComponent = (
  config: TrendSummaryConfig | TrendBubblesConfig | TrendDetailsGridConfig,
  data: BasicPivotItem[],
  groupByDataIndex: string
): JSX.Element => {
  switch (config.type) {
    case 'trendBubbles':
      const trendBubblesConfig = config as TrendBubblesConfig;
      return <TrendBubbles config={trendBubblesConfig} data={data} groupByDataIndex={groupByDataIndex} />;
    case 'trendCards':
      const trendSummaryConfig = config as TrendSummaryConfig;
      return <TrendSummary config={trendSummaryConfig} data={data} />;
    case undefined:
      const trendDetailsConfig = config as TrendDetailsGridConfig;
      return <TrendDetails config={trendDetailsConfig} data={data} />;
    default:
      return <span>Unknown custom renderer for master detail.</span>;
  }
};

const QuickTrends = ({
  onShowView,
  trendsSummaryDataState,
  summaryConfig,
  detailsConfig,
  loaded,
  groupByDataIndex,
  subheaderViewDefns,
  summaryData,
  gridData,
  updateTopMemberSelection,
  isPrintMode,
  floorsetDropdownProps,
  title,
  hideTitle,
  showLookBackPeriod,
  showFlowStatus,
}: QuickTrendsProps) => {
  let dropdowns: SubheaderDropdownProps[] = [];

  const handleSetSelectedFloorset = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (isNil(floorsetDropdownProps)) {
        return;
      }

      const newValue = event.currentTarget.textContent;
      const valueIndex = findIndex(floorsetDropdownProps.options, (option) => {
        return option.text === newValue;
      });
      const selection = floorsetDropdownProps.options[valueIndex].id as string;
      updateTopMemberSelection(selection);
    },
    [floorsetDropdownProps, updateTopMemberSelection]
  );

  if (!isNil(floorsetDropdownProps)) {
    dropdowns = concat(dropdowns, {
      ...floorsetDropdownProps,
      dataQa: 'floor-sets',
      handleChangeOnDropdown: handleSetSelectedFloorset,
    });
  }
  const leftConfig = summaryConfig as TrendBubblesConfig | TrendSummaryConfig;
  const rightConfig = detailsConfig;
  const configs = loaded ? [leftConfig, rightConfig] : undefined;

  useEffect(() => {
    onShowView();
  }, [onShowView]);

  const quickTrendsStyles = styles(isPrintMode, leftConfig?.numberCardsWide ?? 2);
  return (
    <div className={quickTrendsStyles.root}>
      <Subheader
        title={title}
        hideTitle={hideTitle}
        showFlowStatus={showFlowStatus}
        showLookBackPeriod={showLookBackPeriod}
        groupByDefn={subheaderViewDefns.groupBy}
        viewDataState={[trendsSummaryDataState]}
        extraDropdowns={dropdowns}
      />
      <section className={quickTrendsStyles.container} data-qa="quicktrends-container">
        {!configs ? (
          <Overlay type="loading" visible={!loaded} fitParent={true} />
        ) : (
          <React.Fragment>
            <div className={quickTrendsStyles.summary} data-qa="quicktrends-left">
              {trendTypeToComponent(configs[0]!, summaryData, groupByDataIndex)}
            </div>
            <Divider flexItem={true} orientation={'vertical'} />
            <div className={quickTrendsStyles.details} data-qa="quicktrends-right">
              {trendTypeToComponent(configs[1]!, gridData, groupByDataIndex)}
            </div>
          </React.Fragment>
        )}
      </section>
    </div>
  );
};

export default connect(mapStateToProps, dispatchToProps)(QuickTrends);
