import { AnyAction, Dispatch } from 'redux';
import { Observable, repeat, Subscription, switchMap } from 'rxjs';
import { planItemUpdate, undoUpdate, updateAddAsstTracker } from 'src/pages/AssortmentBuild/Planning.slice';
import { receiveWorklist } from 'src/pages/Hindsighting/StyleColorReview/SharedData.slice';
import { updateConfigFingerPrint } from 'src/services/configuration/AppConfig.slice';
import { WorklistInfo } from 'src/worker/pivotWorker.types';
import {
  AssortmentServerEvent,
  AssortmentEventType,
  assortmentEventTypes,
  EventSourceContext,
} from 'src/services/ServerSentEvents/ServerSentEventSource.types';
import { ServerSentEventSource } from 'src/services/ServerSentEvents/ServerSentEventSource';
import { ServerSentEventConnectionError } from 'src/services/ServerSentEvents/ServerSentEventErrors';
import { BaseEventSource } from 'src/services/ServerSentEvents/BaseEventSource';

export class AssortmentEventSource extends BaseEventSource {
  protected context: EventSourceContext = 'assortment';

  constructor(protected onErrorCallback: (error: ServerSentEventConnectionError) => void) {
    // nothing to do here, just need to gain access to onErrorCallback
    super(onErrorCallback);
  }

  protected streamListener(dispatch: Dispatch<AnyAction>, message: Event): void {
    const msg = message as AssortmentServerEvent;
    switch (msg.type) {
      case AssortmentEventType.planQueue:
        const raw = JSON.parse(msg.data);
        dispatch(planItemUpdate(raw as any));
        break;
      case AssortmentEventType.add_to_assortment:
        dispatch(updateAddAsstTracker(JSON.parse(msg.data) as any));
        break;
      case AssortmentEventType.worklist:
        // FIXME: adjust type
        dispatch(receiveWorklist(JSON.parse(msg.data) as WorklistInfo[]));
        break;
      case AssortmentEventType.fingerprint:
        const fingerprint = JSON.parse(msg.data);
        dispatch(updateConfigFingerPrint(fingerprint));
        break;
      case AssortmentEventType.undoChange:
        const undoItem = JSON.parse(msg.data);
        dispatch(undoUpdate(undoItem));
        break;
      case AssortmentEventType.exception:
      default:
        break;
    }
  }

  protected eventSource = (accessToken: string): Observable<Event> => {
    return new Observable((observer) => {
      const eventSource = new ServerSentEventSource(this.context, observer, accessToken, assortmentEventTypes);
      return () => eventSource.unsubscribe();
    });
  };

  public connect = (dispatch: Dispatch<AnyAction>, accessToken$: Observable<string>): Subscription => {
    return accessToken$
      .pipe(switchMap(this.createEventSource))
      .pipe(repeat())
      .subscribe((ev) => this.streamListener(dispatch, ev));
  };
}
