import { style, media } from 'typestyle';

/**
 * Determines the correct break point to have the macros items collapse to a single column
 * based on the collapse state of the sidenav determine.
 */
function calcMediaQueryMinWidth(sideNavCollapsed: boolean) {
  return sideNavCollapsed ? 1450 : 1600;
}

export default {
  calcGroupButtonStyle: (sideNavCollapsed: boolean) => {
    const minWidth = calcMediaQueryMinWidth(sideNavCollapsed);
    return style(media({ minWidth }, { justifyContent: 'right' }), {
      gridArea: 'top-attributes-group-buttons',
      display: 'flex',
    });
  },
  calcTopAttributeStyle: (sideNavCollapsed: boolean) => {
    const minWidth = calcMediaQueryMinWidth(sideNavCollapsed);
    return style(media({ minWidth }, { justifyContent: 'right' }), {
      gridArea: 'top-attributes',
      display: 'flex',
      gap: '1rem',
    });
  },
  rollupsContainer: style({
    gridArea: 'publish-rollups',
    whiteSpace: 'pre',
    fontSize: '1rem',
    display: 'inline-flex',
    alignItems: 'center',
    $nest: {
      '& span': {
        marginRight: 12,
      },
    },
  }),
  splitButtonContainer: style({
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  /** 
   * The outer container wraps:
   * - inner container (which includes the publish rollups and top attributes)
   * - the split button container  
   * */
  macrosOuterContainer: style({
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    $nest: {
      '& > div:first-child': {
        width: '100%',
      },
    },
  }),
  /** 
   * The inner container wraps:
   * - publish rollups
   * - top attributes
   * */
  calcMacrosInnerContainerStyle: (sideNavCollapsed: boolean) => {
    const minWidth = calcMediaQueryMinWidth(sideNavCollapsed);
    return style(
      media(
        { minWidth },
        {
          gridTemplateColumns: '40% 60%',
          gridTemplateAreas: `
          "- top-attributes-group-buttons"
          "publish-rollups top-attributes"
        `,
        }
      ),
      {
        display: 'grid',
        gridTemplateRows: 'auto',
        gridTemplateColumns: '100%',
        gridTemplateAreas: `
          "publish-rollups"
          "top-attributes-group-buttons"
          "top-attributes"
        `,
        rowGap: '0.5rem',
        width: '100%',
        marginBottom: '0.5rem',
      }
    );
  },
};
