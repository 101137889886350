import { TEAL_PRIMARY, GRAY } from 'src/utils/Style/Theme';
import { style } from 'typestyle';

const buttonText = style({
  color: '#ffffff !important',
});

const container = style({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

function contentWrapper(options: { wrapperType: 'error' | 'warning' }) {
  const { wrapperType } = options;
  let borderColor = TEAL_PRIMARY;
  borderColor = wrapperType === 'warning' ? GRAY : borderColor;
  return style({
    padding: '4rem',
    border: `2px solid ${borderColor}`,
    borderRadius: '10px',
    maxWidth: 1000,
  });
}

const textWrapper = style({
  marginBottom: '2rem',
  $nest: {
    '& .warning': {
      fontSize: '1.3rem',
      fontWeight: 'bold',
      marginBottom: '1.5rem',
    },
  },
});
const errorDropdown = style({
  paddingBottom: '2rem',
  $nest: {
    '.error-message-list': {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 250,
      overflow: 'auto',
    },
  },
});
const textHeader = style({ textAlign: 'center' });
const textSpacer = style({ marginBottom: '0.5rem' });
const textItem = style({ fontSize: '0.9rem' });
const subTextItem = style({ fontSize: '0.75rem' });
const lightText = style({ color: GRAY });

export default {
  container,
  buttonText,
  contentWrapper,
  textWrapper,
  textSpacer,
  textHeader,
  textItem,
  subTextItem,
  errorDropdown,
  lightText,
};
