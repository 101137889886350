import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import {
  fetchFloorsetDataForQuickTrends,
  fetchQuickTrendsData,
  fetchQuickTrendsDetailsData,
  receiveTrendsConfig,
  refreshQuickTrendsTrendData,
} from './QuickTrends.slice';
import {
  inputIsNotNullOrUndefined,
  isScopeDefined,
  isSubheaderLoaded,
  topMemInWorklistSelected,
} from 'src/utils/Functions/epicsFunctions';
import {
  ConfDefnComponentType,
  QuickTrendsComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import {
  ExtraPivotOptions,
  organizeListDataOptions,
} from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { receiveScopeRefreshTrigger } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { updateSelectedItem } from 'src/pages/Worklist/Worklist.slice';
import { setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';
import {
  updateGroupBy,
  receiveViewDefns,
  updateFlowStatus,
  updateTopMemberSelection,
  receiveTopMemberDropdownData,
} from 'src/components/Subheader/Subheader.slice';
import { findIndex, isEmpty, isNil, reject } from 'lodash';
import { receiveFloorsetData } from 'src/components/ConfigurableGrid/ConfigurableGrid.slice';
import { parseFloorsetDropdownData } from 'src/components/ConfigurableGrid/utils/ConfigurableGrid.utils';
import { getGroupBySelectedOptionProperty } from 'src/utils/Pivot/Sort';

export const quickTrendsMaybeFloorsetLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveScopeRefreshTrigger.type,
      updateFlowStatus.type,
      setActiveSubPage.type,
      updateSelectedItem.type,
      receiveViewDefns.type
    ),
    map(() => maybeGetComponentProps<QuickTrendsComponent>(state$.value, ConfDefnComponentType.quickTrends)),
    filter(inputIsNotNullOrUndefined),
    filter(() => topMemInWorklistSelected(state$.value)),
    filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    mergeMap(({ floorsetApi }) => {
      if (floorsetApi) {
        return of(fetchFloorsetDataForQuickTrends(floorsetApi));
      }
      return of();
    })
  );
};

export const quickTrendsTrendDataLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      refreshQuickTrendsTrendData.type,
      receiveFloorsetData.type,
      receiveTopMemberDropdownData.type,
      updateTopMemberSelection.type,
      updateGroupBy.type,
      receiveViewDefns.type,
      receiveTrendsConfig.type
    ),
    map(() => maybeGetComponentProps<QuickTrendsComponent>(state$.value, ConfDefnComponentType.quickTrends)),
    filter(inputIsNotNullOrUndefined),
    filter(() => topMemInWorklistSelected(state$.value)),
    filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    mergeMap(({ graphDataApi, gridDataApi, floorsetApi }) => {
      const { selectedLevel } = state$.value.worklist;
      const topMembers = state$.value.worklist.selectedItemId;
      const aggBy = getGroupBySelectedOptionProperty(state$.value.subheader.groupBy, 'groupingKey');
      const flowStatus = state$.value.subheader.flowStatus;
      const { topMemberOptions, topMemberSelection } = state$.value.subheader;

      let floorsetId: string | undefined;
      // Skip loading if topMember data has not yet been fetched *yet* is needed/expected - Denver Pierce
      if (!isNil(floorsetApi)) {
        if (isEmpty(topMemberOptions) || isNil(topMemberOptions)) return of();
        else {
          const options = parseFloorsetDropdownData(topMemberOptions);
          const floorsetIndex = isNil(topMemberSelection)
            ? 0
            : findIndex(options, (option) => option.text === topMemberSelection);
          floorsetId = options[floorsetIndex]?.id || '';
        }
      }
      // disabling this for now as it doesn't work in the new paradigmn
      // we can't fetch data without the required groupings
      if (isEmpty(aggBy)) {
        return of();
      }
      const finalAggBy = [aggBy, graphDataApi.params.aggBy].filter((i) => i != null);
      if (graphDataApi.params.includeLevelBy && selectedLevel != null) {
        finalAggBy.unshift(selectedLevel.dataIndex);
      }
      const listDataTopMembers = reject([topMembers, floorsetId], isNil).join(',');

      // summary data request options
      const summaryOptions: ExtraPivotOptions = {
        topMembers: listDataTopMembers,
        aggBy: finalAggBy.join(','),
        flowStatus: flowStatus.join(','),
      };
      const finalSummaryOptions = organizeListDataOptions(summaryOptions);

      const summaryFetchAction = fetchQuickTrendsData(graphDataApi.defnId, finalSummaryOptions);

      if (!isNil(gridDataApi)) {
        const gridAggBy = [aggBy, gridDataApi.params.aggBy].filter((i) => i != null);
        if (gridDataApi.params.includeLevelBy && selectedLevel != null) {
          gridAggBy.unshift(selectedLevel.dataIndex);
        }
        const gridOptions: ExtraPivotOptions = {
          topMembers: listDataTopMembers,
          aggBy: gridAggBy.join(','),
          flowStatus: flowStatus.join(','),
        };
        const finalgridOptions = organizeListDataOptions(gridOptions);
        const gridFetchAction = fetchQuickTrendsDetailsData(
          gridDataApi.defnId || graphDataApi.defnId,
          finalgridOptions
        );
        return of(summaryFetchAction, gridFetchAction);
      } else {
        return of(summaryFetchAction);
      }
    })
  );
};
