import { AnyAction, Dispatch } from 'redux';
import { Observable } from 'rxjs';
import service from 'src/ServiceContainer';
import { logWarn } from 'src/services/loggingService';
import { AssortmentEventSource } from 'src/services/ServerSentEvents/AssortmentEventSource';
import { MfpEventSource } from 'src/services/ServerSentEvents/MfpEventSource';
import { ServerSentEventConnectionError } from 'src/services/ServerSentEvents/ServerSentEventErrors';

export class ServerSentEventMediator {
  private asstEventSource: AssortmentEventSource;
  private asstConnectionErrorDetected = false;
  private mfpEventSource: MfpEventSource;
  private mfpConnectionErrorDetected = false;

  constructor() {
    this.asstEventSource = new AssortmentEventSource(this.handleError);
    this.mfpEventSource = new MfpEventSource(this.handleError);
  }

  public connect = (dispatch: Dispatch<AnyAction>, accessToken$: Observable<string>) => {
    this.asstEventSource.connect(dispatch, accessToken$);
    this.mfpEventSource.connect(dispatch, accessToken$);
  };

  private handleError = (error: ServerSentEventConnectionError) => {
    service.loggingService.warn(error.message);

    if (error.context === 'assortment') {
      this.asstConnectionErrorDetected = true;
    } else if (error.context === 'mfp') {
      this.mfpConnectionErrorDetected = true;
    }

    this.validateConnections(error);
  };

  /** Checks for at least one successful connection to have occurred, if neither has connected successfully enter failure state  */
  private validateConnections = (error: ServerSentEventConnectionError) => {
    if (this.asstConnectionErrorDetected && this.mfpConnectionErrorDetected) {
      logWarn(`Failed to connect to any real-time communication server. Please contact support.`, error);
    }
  };
}
