import * as React from 'react';
import { Indexable } from 'src/types/Primitive';
import { AppState } from 'src/store';
import { WrappedDispatch, wrappedDispatch } from 'src/utils/Redux/Dispatch';
import { identity } from 'src/utils/Primitive/Function';
import { connect } from 'react-redux';
import { DataGrid } from 'src/common-ui/index';
import { SelectionOverride } from 'src/common-ui/components/Filters/Filters';
import { DataGridProps } from 'src/common-ui/components/DataGrid/DataGrid';
import Renderer from 'src/utils/Domain/Renderer';
import getWeekRangeText from 'src/utils/Functions/Description';
import GridErrorBoundary from 'src/components/ErrorBoundary/GridErrorBoundary';
import { IS_HINDSIGHTING } from 'src/utils/Domain/ConstantsFunctions';
import { FilterModelType } from 'src/components/Sidenav/SideNav.slice';
import { getSectionContext } from 'src/utils/Domain/Perspective';
import { ColumnState } from '@ag-grid-community/core';

type FilterSensitiveProps = any;
type InternalFilterSensitiveProps = {
  customHeader: string;
  parentProps: Indexable;
};

interface FilterSensitiveState {
  gridFilterModel: FilterModelType;
  gridSortModel: ColumnState[];
}

export function getHeaderTextFromState(state: AppState, skipFilters = false): string {
  const filterSelections: SelectionOverride[] = state.filters.selectionOverrides;

  const selectedFilters = filterSelections
    .filter((filterSelection) => filterSelection.value) // only include "enabled" overrides; the overrides may contain values that aren't saved but have been turned off by the user
    .map((filterSelection) => filterSelection.id)
    .toString();
  const filterText = skipFilters ? '' : `Filters: ${selectedFilters} \r\n`;

  const view = state.perspective.activePage;
  const currentContext = getSectionContext();

  const { productMemberName, locationMemberName, historyStart, historyEnd, start, end } = state.scope.scope;
  const { pastRangeList, rangeList } = state.scope;

  const currentScope = IS_HINDSIGHTING(currentContext)
    ? getWeekRangeText(historyStart, historyEnd, pastRangeList)
    : getWeekRangeText(start, end, rangeList);

  return `${productMemberName} | Location - ${locationMemberName} | ${currentScope} \r\n${filterText}Exported from: ${view}`;
}

export function makeDataGridFilterSensitive<T extends React.ComponentClass>(WrappedComponent: T) {
  function mergeProps(state: AppState, _wrapped: WrappedDispatch, parentProps: DataGridProps) {
    const customHeader = getHeaderTextFromState(state);
    const props: InternalFilterSensitiveProps = {
      customHeader,
      parentProps,
    };
    const activeFavorite = state.subheader.favoritesList?.find((fav) => fav.active);
    const favoriteFilterModel: FilterModelType | undefined = activeFavorite?.jsonBlob?.gridFilterModel;
    const favoriteSortModel: ColumnState[] | undefined = activeFavorite?.jsonBlob?.gridSortModel;
    const itemsStatus = {
      planning: state.planTracker.planItems,
      worklist: state.pages.hindsighting.styleColorReview.sharedData.worklist,
    };
    return {
      ...props,
      parentProps: {
        ...parentProps,
        favoriteFilterModel,
        favoriteSortModel,
        itemsStatus,
      },
    };
  }

  class DataGridFilterSensitive extends React.Component<FilterSensitiveProps, FilterSensitiveState> {
    constructor(props: FilterSensitiveProps) {
      super(props);

      this.state = {
        gridFilterModel: {},
        gridSortModel: [],
      };
    }

    storeGridFilterModel = (gridFilterModel: FilterModelType) => {
      this.setState({
        gridFilterModel,
      });
    };

    storeGridSortModel = (gridSortModel: ColumnState[]) => {
      this.setState({
        gridSortModel,
      });
    };

    render() {
      const { gridFilterModel, gridSortModel } = this.state;
      const additionalExcelExports = {
        customHeader: this.props.customHeader,
        excelRendererObj: Renderer.getAllExcelRenderers(),
      };

      const resolvedFilterModel = this.props.parentProps.favoriteFilterModel || gridFilterModel;
      const resolvedSortModel = this.props.parentProps.favoriteSortModel || gridSortModel;
      const finalParentProps = {
        ...this.props.parentProps,
        gridFilterModel: resolvedFilterModel,
        gridSortModel: resolvedSortModel,
        storeGridFilterModel: this.storeGridFilterModel,
        storeGridSortModel: this.storeGridSortModel,
        itemsStatus: this.props.parentProps.itemsStatus,
      };
      return (
        <React.Fragment>
          <GridErrorBoundary errorTitle={'Something has gone wrong with the grid'}>
            <WrappedComponent
              {...finalParentProps}
              exportOptions={{
                ...additionalExcelExports,
                ...finalParentProps.exportOptions,
              }}
            />
          </GridErrorBoundary>
        </React.Fragment>
      );
    }
  }
  // @ts-ignore
  return connect(identity, wrappedDispatch, mergeProps)(DataGridFilterSensitive);
}

// @ts-ignore
export default makeDataGridFilterSensitive(DataGrid);
