import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { LicenseManager } from '@ag-grid-enterprise/core';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-material.css';
import store, { AppState, persistor } from './store';
import serviceContainer, { ServiceContainer } from 'src/ServiceContainer';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import NavigationShell from 'src/pages/NavigationShell/NavigationShell.container';
import PerspectiveSelection from 'src/pages/PerspectiveSelection/PerspectiveSelection.container';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { updateConfDefns, updateConfigFingerPrint } from 'src/services/configuration/AppConfig.slice';
import { enableAllPlugins } from 'immer';
import { fetchFingerprintFactory } from 'src/services/configuration';
import { PersistGate } from 'redux-persist/integration/react';

import { startup, explicitLogout$, loginError$, explicitLogin, accessToken$ } from './services/auth/platform';

import { combineLatest, asyncScheduler, subscribeOn, map, combineLatestWith, filter, Observable } from 'rxjs';
import { Logout } from './components/Logout/Logout';
import { Overlay } from './common-ui';
import { ServerSentEventMediator } from './services/ServerSentEvents/ServerSentEventMediator';

import '../node_modules/@fortawesome/fontawesome-pro/css/all.css';
import '../node_modules/@trendmicro/react-modal/dist/react-modal.css';
import '../node_modules/@trendmicro/react-toggle-switch/dist/react-toggle-switch.css';
import '../node_modules/npm-font-open-sans/open-sans.scss';

import 'semantic-ui-sass/semantic-ui.scss';
import './_global.scss';
import './global.css';

enableAllPlugins();

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-044159 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( S5 Stratos )_is_granted_a_( Single Application )_Developer_License_for_the_application_( S5Stratos )_only_for_( 5 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( S5Stratos )_need_to_be_licensed___( S5Stratos )_has_been_granted_a_Deployment_License_Add-on_for_( 5 )_Production_Environments___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 25 June 2024 )____[v2]_MTcxOTI3MDAwMDAwMA==143c7acbabc441693cfa6492d8b5ccd4'
);

const { printService, configService } = serviceContainer;

const root = document.getElementById('root');

type LateRootState = {
  store: typeof store;
};

type RootState = {
  explicitLogout: boolean;
  error: boolean;
  lateInit?: LateRootState;
};

const definedAccessToken$: Observable<string> = accessToken$
  .asObservable()
  .pipe(filter((x) => x !== null))
  .pipe(map((x) => x as string));

class RootComponent extends React.Component<{}, RootState> {
  componentDidMount() {
    // Always start this immediatley
    fetchFingerprintFactory()
      .then((fingerprint) => {
        store.dispatch(updateConfigFingerPrint(fingerprint));
      })
      .catch(() => {
        console.info('Config has no fingerprint.'); // eslint-disable-line no-console
      });

    const configError$ = configService.errored$.pipe(map((e) => !!e));

    const anError$ = loginError$.pipe(combineLatestWith(configError$)).pipe(map(([el, ec]) => el || ec));

    combineLatest([explicitLogout$, anError$])
      .pipe(subscribeOn(asyncScheduler))
      .subscribe(([explicitLogout, error]) => {
        this.setState({ explicitLogout, error });
      });

    combineLatest([configService.configuration$]).subscribe(async ([uiConfs]) => {
      // setup sse connections
      const eventMediator = new ServerSentEventMediator();
      eventMediator.connect(store.dispatch, definedAccessToken$);

      // setup print service
      printService.hydrate(store);
      printService.setPrintFromUrl();
      window.onpopstate = printService.setPrintFromUrl;

      // store confdefns in redux
      store.dispatch(updateConfDefns(uiConfs));

      // initialize state with store
      this.setState({ ...this.state, lateInit: { store } });

      await fetchFingerprintFactory()
        .then((fingerprint) => store.dispatch(updateConfigFingerPrint(fingerprint)))
        // eslint-disable-next-line no-console
        .catch(() => console.info('Config has no fingerprint.'));

      // TODO: manage this state better.
      window.__debug.bootState = store.getState();
    });
  }

  render() {
    if (this.state?.error) {
      return <Overlay visible={true} fitParent={true} type={'error'} />;
    } else if (this.state?.explicitLogout) {
      return <Logout message={'You are logged out'} onLogin={() => explicitLogin()} />;
    } else if (this.state?.lateInit) {
      return (
        <div>
          <Provider store={this.state.lateInit.store}>
            <PersistGate loading={null} persistor={persistor}>
              <Router>
                <Routes>
                  <Route path="/" element={<PerspectiveSelection />} />
                  {/* Because of nested routes, only care about matching perspective  */}
                  {/* @ts-ignore TODO fix router types */}
                  <Route path={':perspective/*'} element={<NavigationShell />} />
                </Routes>
              </Router>
            </PersistGate>
          </Provider>
          <ToastContainer autoClose={5000} />
        </div>
      );
    } else {
      return <Overlay visible={true} fitParent={true} type={'loading'} />;
    }
  }
}

// Do startup
startup();
ReactDOM.render(<RootComponent />, root);

declare global {
  interface Window {
    __debug: {
      store: Store<AppState>;
      serviceContainer: ServiceContainer;
      bootState?: AppState;
    };
  }
}

window.__debug = {
  store,
  serviceContainer,
};
