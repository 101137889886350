import { isEmpty } from 'lodash';
import { ARROWDIRECTIONS, ArrowValueRendererProps } from 'src/components/ArrowValueRenderer/ArrowValueRenderer';
import { TopAttribute } from 'src/services/configuration/codecs/viewdefns/general';
import Renderer from 'src/utils/Domain/Renderer';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';

function getArrowFromValue(value: number) {
  // Math.sign return 1 for positive, 0 for 0, and -1 for negative
  switch (Math.sign(value)) {
    case 1:
    case 0:
      return ARROWDIRECTIONS.UP;
    case -1:
    default:
      return ARROWDIRECTIONS.DOWN;
  }
}

/**
 * Builds the component props for `ArrowValueRenderer` from a `topAttributes.right` config.
 */
export function generateTopAttributesProps(data: BasicPivotItem, configuratorViewDefn: any): ArrowValueRendererProps[] {
  return configuratorViewDefn.topAttributes.right.map((config: TopAttribute) => {
    const { classes, dataIndex, groupingKey = '', hidden, mask, renderer, text, view } = config;
    // Compute the parent's value using the parent's renderer.
    const parentRenderer = Renderer[renderer];
    const parentValue = parentRenderer ? parentRenderer(data[dataIndex], mask, classes) : '';

    // Compute child metrics if a view array exists.
    const childMetrics =
      view && Array.isArray(view)
        ? view.map((childConfig: TopAttribute) => {
            const childRenderer = Renderer[childConfig.renderer];
            const childValue = data[childConfig.dataIndex];
            const rendereredChildValue = childRenderer ? childRenderer(childValue) : '';
            return {
              label: childConfig.text ?? '',
              value: rendereredChildValue,
            };
          })
        : undefined;

    const arrowDirection = getArrowFromValue(data[dataIndex]);
    let defaults = configuratorViewDefn.topAttributes.defaults as string[];
    // FIXME: Only support single select for now, support multiselect as config option
    // In single select mode, this will set default to first grouping key.
    if (isEmpty(defaults)) {
      const firstGroup = configuratorViewDefn.topAttributes.right[0].groupingKey ?? '';
      defaults = [firstGroup];
    }
    const output: ArrowValueRendererProps = {
      header: text ?? '',
      value: parentValue,
      arrowDirection,
      group: groupingKey,
      hidden,
      // FIXME: Only support single select for now, support multiselect as config option
      selected: defaults[0] === groupingKey,
      // selected: isEmpty(defaults) ? true : defaults.includes(groupingKey),
      childMetrics,
    };

    return output;
  });
}
