import { style, classes } from 'typestyle';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import { important, px } from 'csx';

export const InputIntegerClass = style({
  $debugName: 'inputIntegerClass',
  fontSize: '0.7rem',
});

export const REC_ADJ_COLUMN_WIDTH = 75;

let styles: { [key: string]: string } = {
  overlay: style({
    position: 'fixed',
    background: 'rgba(0, 0, 0, .5)',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  modalContainer: style({
    background: 'white',
    boxShadow: '3px 3px 9px 3px rgba(0,0,0,0.44)',
    maxWidth: '95vw',
    minWidth: '40vw',
  }),
  modalContainerForNoData: style({
    background: 'white',
    boxShadow: '3px 3px 9px 3px rgba(0,0,0,0.44)',
  }),
  modalArrowStyle: style({
    color: 'white',
    display: 'block',
    position: 'absolute',
    borderTop: '25px solid transparent',
    borderBottom: '25px solid transparent',
    borderLeft: '25px solid white',
    right: '-25px',
    bottom: 0,
  }),
  cardHeader: style({
    width: '100%',
    color: 'black',
    fontSize: '1.3rem',
    background: 'rgb(244, 243, 244)',
    padding: 10,
  }),
  tableHeader: style({
    // width: '100%',
  }),
  container: style({
    $debugName: 'containerWithData',
    background: 'white',
    padding: '0.5rem',
    height: 500,
    overflow: 'auto',
  }),
  containerNoData: style({
    $debugName: 'containerNoData',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    background: 'white',
    padding: 20,
    overflow: 'auto',
    minHeight: 500,
    minWidth: '720px',
    fontSize: '1.2rem',
    position: 'relative',
  }),
  receiptContainer: style({
    padding: 10,
    position: 'relative',
  }),
  tabContainer: style({
    $debugName: 'tabContainer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    height: 40,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  }),
  tabButton: style({
    textAlign: 'center',
    height: '100%',
    flexGrow: 1,
    border: 'none',
    outline: 0,
    color: 'grey',
    fontWeight: 600,
    letterSpacing: 1.4,
    textTransform: 'uppercase',
    fontSize: '0.95em',
    background: 'transparent',
    $nest: {
      '&:focus': {
        outline: 0,
      },
    },
  }),
  actionsContainer: style({
    width: '100%',
    paddingRight: 8,
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: 'solid 1px rgba(224, 224, 224, 1)',
    $nest: {
      button: {
        marginLeft: 10,
      },
    },
  }),
  iconButton: style({
    float: 'right',
    width: 35,
    height: 35,
    minWidth: 0,
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  sectionText: style({
    fontSize: '1.05em',
    fontWeight: 600,
    letterSpacing: 1.4,
    textTransform: 'uppercase',
  }),
  tableRow: style({
    $debugName: 'tableRow',
    height: '35px',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(0, auto))',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  }),
  tableCell: style({
    $debugName: 'tableCell',
    width: px(REC_ADJ_COLUMN_WIDTH),
    padding: 0,
    overflow: 'hidden',
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: '0.7rem',
  }),
  tableCellInput: style({
    $debugName: 'tableCellInputContainer',
    $nest: {
      '& input': {
        textAlign: 'center',
        verticalAlign: 'middle',
        width: px(REC_ADJ_COLUMN_WIDTH),
        height: '34px', // takes into account the bottom border so need 1 px less to render border
        fontSize: '0.8rem !important',
        fontWeight: important('bold'),
        border: 'none',
        background: 'none',
      },
    },
  }),
  headerSection: style({
    $debugName: 'headerSection',
    paddingBottom: 10,
  }),
  tableSection: style({
    $debugName: 'tableSection',
    position: 'relative',
  }),
  tableHeaderRow: style({
    $debugName: 'tableHeaderRow',
    position: 'sticky',
    top: '-0.5rem',
    zIndex: 1,
    background: 'white',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    height: '35px',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(0, auto))',
  }),
};

styles = {
  ...styles,
  tableCellBody: classes(
    styles.tableCell,
    style({
      $debugName: 'tableCellBody',
      fontWeight: 400,
    })
  ),
  tableCellTotal: classes(
    styles.tableCell,
    style({
      $debugName: 'tableCellTotal',
      fontWeight: 600,
    })
  ),
  tableCellHeader: classes(
    styles.tableCell,
    style({
      $debugName: 'tableCellHeader',
      color: 'black',
      fontWeight: 500,
    })
  ),
  tableCellInputEditable: classes(
    styles.tableCell,
    styles.tableCellInput,
    style({
      $debugName: 'tableCellInputContainerEditable',
      $nest: {
        '& input': {
          background: 'rgb(220, 243, 241)',
        },
      },
    })
  ),
  tabButtonActive: classes(
    styles.tabButton,
    style({
      color: important(TEAL_PRIMARY), // for some reason this value was not overriding tabButton color
    })
  ),
  iconButtonSave: classes(
    styles.iconButton,
    style({
      background: '#00a89b',
      color: '#f9f9f9',
    })
  ),
  iconButtonCancel: classes(
    styles.iconButton,
    style({
      background: 'white',
      marginLeft: 5,
      borderColor: 'rgba(0,0,0,0.54)',
      color: 'rgba(0,0,0,0.54)',
    })
  ),
};
styles = {
  ...styles,
  tableCellBodyFirst: classes(
    styles.tableCellBody,
    style({
      borderBottom: 0,
      textAlign: 'right',
      paddingRight: 10,
    })
  ),
  tableCellTotalFirst: classes(
    styles.tableCellTotal,
    style({
      borderBottom: 0,
      textAlign: 'right',
      paddingRight: 10,
    })
  ),
  tableCellHeaderFirst: classes(
    styles.tableCellHeader,
    style({
      borderBottom: 0,
      color: 'white',
    })
  ),
};

export const ReceiptStyles = styles;

export const MUIStyles: any = {
  card: style({
    maxWidth: 700,
  }),
  cardContent: {
    root: style({
      minHeight: 305,
      fontSize: '13px',
    }),
  },
  cardHeader: {
    root: style({
      backgroundColor: '#F0F0F0',
      height: '50px',
    }),

    content: style({
      $nest: {
        '& span': {
          fontSize: '15px',
        },
      },
    }),
  },
  cardActions: {
    root: style({
      justifyContent: 'flex-end',
      $nest: {
        button: {
          marginLeft: '10px',
        },
      },
    }),
  },
};
