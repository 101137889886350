import i18n, { PostProcessorModule } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { capitalize } from 'lodash';

export const supportedLanguages = [
  { code: 'en-us', name: 'English (United States)', default: true },
  { code: 'en-gb', name: 'English (United Kingdom)' },
];

/**
 * Capitalize the first letter of the value.
 *
 * A post processor is preferred instead of a custom formatter/interpolation
 * because this method does not require the json to be modified
 * to handle the formatter that is necessary for interpolation.
 */
const capitalizePostProcessor: PostProcessorModule = {
  type: 'postProcessor',
  name: 'capitalize',
  process: (value: string) => capitalize(value),
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .use(capitalizePostProcessor)
  .init({
    lng: navigator.language || 'en-us',
    supportedLngs: supportedLanguages.map((l) => l.code),
    fallbackLng: 'en-us',
    lowerCaseLng: true, // only uses lowercase language codes
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
