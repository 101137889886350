import { forEach } from 'lodash';
import { Observer } from 'rxjs';
import service from 'src/ServiceContainer';
import { EventSourceContext } from './ServerSentEventSource.types';
import { API_BASE_URL } from 'src/state/ViewConfig/ViewConfig.slice';
import { ServerSentEventConnectionError } from './ServerSentEventErrors';

export class ServerSentEventSource {
  private context: EventSourceContext = 'assortment'; // default to assortment for now
  private observer: Observer<Event>;
  private eventOpened = false;
  private eventSource: EventSource;
  private expectedEventTypes: Set<string>;
  private receivedEventTypes = new Set<string>();
  private noEventsConnectionTimeout: NodeJS.Timeout;
  private missingEventTypesTimeout: NodeJS.Timeout | undefined = undefined;

  constructor(context: EventSourceContext, observer: Observer<Event>, accessToken: string, eventTypes: string[]) {
    this.observer = observer;
    this.context = context;

    const sourceUrl = this.context === 'assortment' ? '/asst/api/bus/events' : `${API_BASE_URL}/events`;
    this.eventSource = new EventSource(`${sourceUrl}?token=${encodeURIComponent(accessToken)}`);
    this.expectedEventTypes = new Set<string>(eventTypes);

    // setup event listeners
    this.eventSource.addEventListener('open', this.onOpen, false);
    this.eventSource.addEventListener('error', this.onError, false);
    this.expectedEventTypes.forEach((msgType) => {
      this.eventSource.addEventListener(msgType, this.onMessage, false);
    });

    // start a timer to handle no connection after 5 seconds
    this.noEventsConnectionTimeout = setTimeout(() => this.onNoEventsConnection(), 5000);
  }

  private onOpen = () => {
    this.eventOpened = true;
    // start a timer to log missing events after 10 seconds
    this.missingEventTypesTimeout = setTimeout(() => this.onMissingEventTypes(), 10000);
  };

  private onMessage = (e: Event) => {
    // store message type for validation that all messages are being received
    const msgType = e.type;
    this.receivedEventTypes.add(msgType);
    this.observer.next(e);
  };

  private onError = (err: Event) => {
    if (err.currentTarget) {
      this.observer.error(err);
    }

    this.cleanup();
  };

  private onNoEventsConnection = () => {
    if (!this.eventOpened) {
      // trigger failure state here
      const err = new ServerSentEventConnectionError(this.context);
      this.observer.error(err);
    }

    clearTimeout(this.noEventsConnectionTimeout);
  };

  private onMissingEventTypes = () => {
    const missingEventTypes = Array.from(this.expectedEventTypes).filter((type) => !this.receivedEventTypes.has(type));
    if (missingEventTypes.length > 0) {
      const eventList = missingEventTypes.join(', ');
      service.loggingService.warn(`Missing event types from ${this.context} event source: ${eventList}`);
    }

    clearTimeout(this.missingEventTypesTimeout);
  };

  private cleanup = () => {
    service.loggingService.warn(`Cleaning up all ${this.context} ServerSentEventSource listeners`);

    // this is precautionary in case an error occurs before these
    // event handlers are completed and can clean up their own timeouts
    clearTimeout(this.noEventsConnectionTimeout);
    clearTimeout(this.missingEventTypesTimeout);

    forEach(Array.from(this.receivedEventTypes), (msgType) => {
      this.eventSource.removeEventListener(msgType, this.onMessage);
    });

    this.eventSource.removeEventListener('error', this.onError, false);
    this.eventSource.removeEventListener('open', this.onOpen, false);
    this.eventSource.close();
  };

  public unsubscribe = () => {
    this.cleanup();
  };
}
