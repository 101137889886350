import { z } from 'zod';
import {
  ConfigurableGridComponentProps,
  WorklistComponentProps,
  zGeoTrendsComponentProps,
} from './confdefnComponentProps';
import {
  CollectionViewOwnProps,
  GridViewOwnProps,
  ColumnGroupedViewOwnProps,
  StyleByLevelOvertimeOwnProps,
  SummaryViewOwnProps,
  QuickTrendsOwnProps,
  NestedViewOwnProps,
  EnhancedOvertimeOwnProps,
  NestedAttributeOwnProps,
  CategorySummaryOwnProps,
  ProductMixOwnProps,
} from './ownProps';
import { ConfDefnComponentType } from './confdefnComponents';
import { ListDataApi } from './confdefnView';

/**
 * @deprecated
 *
 * Please use ConfDefnComponentType instead.
 *
 * This is no longer the preferred type values for configuring worklist components.
 * It is here strictly for backward compatibility.
 * */
export enum LegacyWorklistComponentType {
  visualize = 'visualize',
  pricing = 'pricing',
  flowsheet = 'flowsheet',
  styleEdit = 'styleEdit',
  collectionView = 'CollectionView',
  gridView = 'GridView',
  flowType = 'FlowType',
  summaryView = 'SummaryView',
  nestedView = 'NestedView',
  nestedStyleOverTime = 'NestedStyleOvertime',
  nestedOvertime = 'NestedOvertime',
}

export interface TabbedComponentWrapperOwnProps {
  selectedItemId: string;
  viewDefns: string[];
  massEditModel?: string;
  showFlowStatus?: boolean;
  dataApi?: ListDataApi;
  styles?: any[]; // TODO fix this later
  showUndoBtn?: boolean;
}

type WorklistTabWithComponentProps =
  | WorklistTabCollectionViewTab
  | WorklistTabGridViewTab
  | WorklistTabFlowTypeViewTab
  | WorklistTabSummaryViewViewTab
  | WorklistTabNestedViewViewTab
  | WorklistTabEnhancedOvertimeViewTab
  | WorklistTabQuickTrendsViewTab
  | WorklistTabConfigurableGridTab
  | WorklistTabReceiptGridTab
  | WorklistTabStyleOvertimeViewTab
  | WorklistTabNestedOvertimeViewTab
  | WorklistTabGeoTrends
  | WorklistCategorySummary
  | WorklistTabNestedAttribute
  | WorklistTabProductMix;

export type WorklistTab =
  | WorklistTabVisualizeViewTab
  | WorklistTabPricingViewTab
  | WorklistTabFlowSheetViewTab
  | WorklistTabStyleEditViewTab
  | WorklistTabWithComponentProps;

export function isWorklistTabWithComponentProps(
  tab: Pick<WorklistTab, 'componentType'>
): tab is WorklistTabWithComponentProps {
  return (
    tab.componentType === ConfDefnComponentType.collectionView ||
    tab.componentType === ConfDefnComponentType.gridView ||
    tab.componentType === ConfDefnComponentType.flowType ||
    tab.componentType === ConfDefnComponentType.summaryView ||
    tab.componentType === ConfDefnComponentType.nestedView ||
    tab.componentType === ConfDefnComponentType.enhancedOvertime ||
    tab.componentType === ConfDefnComponentType.quickTrends ||
    tab.componentType === ConfDefnComponentType.configurableGrid ||
    tab.componentType === ConfDefnComponentType.receiptGrid ||
    tab.componentType === ConfDefnComponentType.flowSheet ||
    tab.componentType === ConfDefnComponentType.nestedStyleOverTime ||
    tab.componentType === ConfDefnComponentType.nestedOvertime ||
    tab.componentType === ConfDefnComponentType.geoTrends ||
    tab.componentType === ConfDefnComponentType.categorySummary ||
    tab.componentType === ConfDefnComponentType.nestedAttribute ||
    tab.componentType === ConfDefnComponentType.productMix
  );
}

interface WorklistTabSuper {
  title: string;
  pathSlot: string;
}

interface WorklistTabProplessSuper extends WorklistTabSuper {
  // TODO: match new defns structure from confDefn
  viewDefns: string[];
  massEditModel?: string;
  showFlowStatus?: boolean;
  // See INT-4060
  model?: string;
}

export interface WorklistTabVisualizeViewTab extends WorklistTabProplessSuper {
  componentType: ConfDefnComponentType.visualize | LegacyWorklistComponentType.visualize;
  componentProps: unknown;
}

export interface WorklistTabPricingViewTab extends WorklistTabProplessSuper {
  componentType: ConfDefnComponentType.pricing | LegacyWorklistComponentType.pricing;
  componentProps: TabbedComponentWrapperOwnProps;
}

export interface WorklistTabFlowSheetViewTab extends WorklistTabProplessSuper {
  componentType: ConfDefnComponentType.flowSheet | LegacyWorklistComponentType.flowsheet;
  componentProps: TabbedComponentWrapperOwnProps;
}

export interface WorklistTabStyleEditViewTab extends WorklistTabProplessSuper {
  componentType: ConfDefnComponentType.styleEdit | LegacyWorklistComponentType.styleEdit;
  componentProps: unknown;
}

export interface WorklistTabCollectionViewTab extends WorklistTabSuper {
  componentType: ConfDefnComponentType.collectionView | LegacyWorklistComponentType.collectionView;
  componentProps: CollectionViewOwnProps;
}

export interface WorklistTabGridViewTab extends WorklistTabSuper {
  componentType: ConfDefnComponentType.gridView | LegacyWorklistComponentType.gridView;
  componentProps: GridViewOwnProps;
}

export interface WorklistTabFlowTypeViewTab extends WorklistTabSuper {
  componentType: ConfDefnComponentType.flowType | LegacyWorklistComponentType.flowType;
  componentProps: ColumnGroupedViewOwnProps;
}

export interface WorklistTabStyleOvertimeViewTab extends WorklistTabSuper {
  componentType: ConfDefnComponentType.nestedStyleOverTime | LegacyWorklistComponentType.nestedStyleOverTime;
  componentProps: StyleByLevelOvertimeOwnProps;
}

export interface WorklistTabNestedOvertimeViewTab extends WorklistTabSuper {
  componentType: ConfDefnComponentType.nestedOvertime | LegacyWorklistComponentType.nestedOvertime;
  componentProps: StyleByLevelOvertimeOwnProps;
}

export interface WorklistTabSummaryViewViewTab extends WorklistTabSuper {
  componentType: ConfDefnComponentType.summaryView | LegacyWorklistComponentType.summaryView;
  componentProps: SummaryViewOwnProps;
}

export interface WorklistTabQuickTrendsViewTab extends WorklistTabSuper {
  componentType: ConfDefnComponentType.quickTrends;
  componentProps: QuickTrendsOwnProps;
}

export interface WorklistTabNestedViewViewTab extends WorklistTabSuper {
  componentType: ConfDefnComponentType.nestedView | LegacyWorklistComponentType.nestedView;
  componentProps: NestedViewOwnProps;
}

export interface WorklistTabEnhancedOvertimeViewTab extends WorklistTabSuper {
  componentType: ConfDefnComponentType.enhancedOvertime;
  componentProps: EnhancedOvertimeOwnProps;
}

export interface WorklistTabConfigurableGridTab extends WorklistTabSuper {
  componentType: ConfDefnComponentType.configurableGrid;
  //@ts-ignore
  componentProps: z.infer<typeof ConfigurableGridComponentProps>;
}

export interface WorklistTabReceiptGridTab extends WorklistTabSuper {
  componentType: ConfDefnComponentType.receiptGrid;
  // @ts-ignore
  componentProps: z.infer<typeof ConfigurableGridComponentProps>;
}

export interface WorklistTabGeoTrends extends WorklistTabSuper {
  componentType: ConfDefnComponentType.geoTrends;
  componentProps: z.infer<typeof zGeoTrendsComponentProps>;
}
export interface WorklistCategorySummary extends WorklistTabSuper {
  componentType: ConfDefnComponentType.categorySummary;
  componentProps: CategorySummaryOwnProps;
}
export interface WorklistTabNestedAttribute extends WorklistTabSuper {
  componentType: ConfDefnComponentType.nestedAttribute;
  componentProps: NestedAttributeOwnProps;
}
export interface WorklistTabProductMix extends WorklistTabSuper {
  componentType: ConfDefnComponentType.productMix;
  componentProps: ProductMixOwnProps;
}
export interface WorklistOwnProps extends z.infer<typeof WorklistComponentProps> {}
