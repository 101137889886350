import _, { isEqual, isNil } from 'lodash';
import { BindAll } from 'lodash-decorators';
import React from 'react';
// @ts-ignore
import keydown from 'react-keydown';
import { connect } from 'react-redux';
import { Button, Dropdown, Icon, Segment, DropdownProps } from 'semantic-ui-react';
import DraggableContainer from 'src/components/DraggableContainer/DraggableContainer';
import MinimizableItem from 'src/components/DraggableContainer/MinimizableItem';
import LoadingMask from 'src/components/LoadingMask/LoadingMask';
import LockToggle from 'src/components/LockToggle/LockToggle';
import TitledModal from 'src/components/Modal/TitledModal';
import AgPivot, { AgPivot as IAgPivot } from 'src/components/Mfp/Pivot/AgPivot';
import PivotConfigurator from 'src/components/Mfp/PivotConfigurator/PivotConfigurator';
import { ConfigGroup } from 'src/components/Mfp/PivotConfigurator/PivotConfigurator.types';
import {
  PivotOptions,
  getAvailableListing,
  getEffeciveGroups,
  getGroupFromConfigItem,
  getUnFilteredGroupFromConfigItem,
  DimensionItem,
} from 'src/components/Mfp/PivotConfigurator/utils';
import { PivotCell, CELL_TAG_LOCKED, CELL_TAG_FROZEN } from 'src/pivot/PivotCell';
import PivotConfig from 'src/pivot/PivotConfig';
import { configItemToSimpleFavorite, FavoritesEntry } from 'src/services/Favorites';
import './_visualize-summary-pivot.scss';
import { mapDispatchToProps, mapStateToProps } from './favorite.container';
import { FavoriteProps, FavoriteState } from './visualize-summary-pivot-ag.types';
import { API_BASE_URL } from 'src/state/ViewConfig/ViewConfig.slice';
import { isScopeNotReady } from 'src/state/scope/Scope.types';
import { ReportStatus } from 'src/services/Reports.client';
import { toast } from 'react-toastify';
import MacroSummaries from 'src/components/Mfp/MacroSummaries/MacroSummaries';
import { CONTEXT_PENDING, CONTEXT_READY } from 'src/state/workingSets/workingSets.types';
import { VisualizeContextMenu } from 'src/components/VisualizeContextMenu/VisualizeContextMenu';
import { getCellStateFromCells } from 'src/pivot/PivotCells.utils';
import GridErrorBoundary from 'src/components/ErrorBoundary/GridErrorBoundary';
import { withTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { withRouter } from 'src/components/higherOrder/withRouter';
import MfpSubheader from 'src/components/Mfp/MfpSubheader/MfpSubheader';
import serviceEnv from 'src/ServiceContainer';
import { makeMfpScopeSensitive } from 'src/components/higherOrder/MfpScopeSensitive';
import { Group } from 'src/pivot/Group';

const initState = {
  isSaving: false,
  isConfigModalOpen: false,
  allAvailableListing: [],
  availableGroup: [],
  rowGroup: [],
  colGroup: [],
  contextItems: [],
  configFromSubmit: false,
};
@BindAll()
export class Favorite extends React.Component<FavoriteProps, FavoriteState> {
  public constructor(props: FavoriteProps) {
    super(props);
    this.state = Favorite.getDerivedStateFromProps(props, {
      isSaving: false,
      isConfigModalOpen: false,
      allAvailableListing: [],
      availableGroup: [],
      rowGroup: [],
      colGroup: [],
      contextItems: [],
      configFromSubmit: false,
    });
  }

  public pivot!: IAgPivot;
  public configurator!: PivotConfigurator;

  public static getDerivedStateFromProps(props: FavoriteProps, state: FavoriteState) {
    const newFavCondition = props.favoriteId !== state.favoriteValue
    const newState = newFavCondition ? { ...initState } : { ...state };
    const mainConfig = props.mainConfig;
    const settings = props.settings;

    if (!mainConfig || !settings || isScopeNotReady(mainConfig)) {
      return newState;
    }

    newState.favoriteValue = props.favoriteId;

    let faveEntry: FavoritesEntry | undefined;
    if (props.favorites) {
      faveEntry = props.favorites.find((entry) => entry.key === props.favoriteId);
    }
    if (faveEntry && !newState.configFromSubmit) {
      const maybeAlternateDims = [
        faveEntry.value.rows.find((rd) => rd.hierarchy),
        faveEntry.value.columns.find((cd) => cd.hierarchy),
      ].filter((dd) => !!dd);

      newState.allAvailableListing = getAvailableListing(mainConfig, settings, maybeAlternateDims as DimensionItem[]);

      const faveGroupOpts = faveEntry.value;
      const effective = getEffeciveGroups(newState.allAvailableListing, faveGroupOpts);
      newState.availableGroup = effective.available;
      newState.rowGroup = effective.row;
      newState.colGroup = effective.column;
    } else if (newState.configFromSubmit) {
      // do nothing, the state is set in onLayoutManagerSubmit
    } else {
      newState.availableGroup = newState.allAvailableListing;
      newState.rowGroup = [];
      newState.colGroup = [];
    }
    if (newFavCondition) {
      const levelsMap = _.keyBy(_.flatMap(mainConfig.levels), 'id');
      const faveEntry = props.favorites?.find((entry) => entry.key === props.favoriteId);
      if (faveEntry) {
        const faveGroupOpts = faveEntry?.value;
        const rowDimensions = faveGroupOpts.rows.map((entry) => entry.dimension);
        const colDimensions = faveGroupOpts.columns.map((entry) => entry.dimension);
        const dimRowKeyToIndex = _.mapValues(
          _.keyBy(
            _.map(rowDimensions, (d, i) => ({ d, i })),
            'd'
          ),
          'i'
        );
        const dimColKeyToIndex = _.mapValues(
          _.keyBy(
            _.map(colDimensions, (d, i) => ({ d, i })),
            'd'
          ),
          'i'
        );
        let rowMemberInfo: ConfigGroup[] = newState.rowGroup;
        let colMemberInfo: ConfigGroup[] = newState.colGroup;

        rowMemberInfo = _.sortBy(rowMemberInfo, (g) => dimRowKeyToIndex[g.id]);
        colMemberInfo = _.sortBy(colMemberInfo, (g) => dimColKeyToIndex[g.id]);

        const pivotRows = rowMemberInfo.map((mi) => getGroupFromConfigItem(mi, faveGroupOpts, levelsMap));
        const pivotCols = colMemberInfo.map((mi) => getGroupFromConfigItem(mi, faveGroupOpts, levelsMap));

        newState.pivotConfig = new PivotConfig({
          scopeId: props.scopeId,
          rows: pivotRows,
          columns: pivotCols,
        });
      } else {
        newState.pivotConfig = undefined
      }
    }

    return newState;
  }

  public toggleLock() {
    const { isLockToggled, onUnlockAllClick, scopeId } = this.props;
    if (isLockToggled && scopeId) {
      if (onUnlockAllClick) {
        onUnlockAllClick(this, serviceEnv.axios, scopeId);
      }
    }
  }

  public onSaveWorkingPlan() {
    const { saveWorkingPlan, scopeId } = this.props;

    if (saveWorkingPlan && scopeId) {
      this.setState({
        isSaving: true,
      });
      saveWorkingPlan(serviceEnv.axios, scopeId).then(() => {
        this.setState({
          isSaving: false,
        });
      });
    }
  }

  /*** KEYBOARD EVENTS ***/
  // undo grid
  @keydown('ctrl+z', 'meta+z')
  public keyUndo(e: React.KeyboardEvent) {
    // check for edit state? can this affect the inputs?
    e.preventDefault();
    e.stopPropagation();
    this.onClickUndo();
  }

  // redo grid
  @keydown('ctrl+y', 'meta+y')
  public keyRedo(e: React.KeyboardEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.onClickRedo();
  }

  // lock cell
  // TODO: change to freeze
  @keydown('ctrl+g')
  public keyLock(e: React.KeyboardEvent) {
    e.preventDefault();
    e.stopPropagation();

    const pivot = this.pivot;
    let cells: PivotCell[] = [];

    if (pivot) {
      cells = pivot.getSelectedCells();
    }

    const isUnlockable = cells.filter((cell: PivotCell) => cell.hasTag(CELL_TAG_LOCKED)).length === cells.length;

    if (isUnlockable) {
      this.relaxCells();
    }
    this.lockCells();
  }

  // freeze cell
  @keydown('ctrl+e')
  public keyFreeze(e: React.KeyboardEvent) {
    e.preventDefault();
    e.stopPropagation();

    const pivot = this.pivot;
    let cells: PivotCell[] = [];

    if (pivot) {
      cells = pivot.getSelectedCells();
    }

    const isFreezeable = cells.filter((cell: PivotCell) => cell.hasTag(CELL_TAG_FROZEN)).length === cells.length;

    if (isFreezeable) {
      this.relaxCells();
    }
    this.freezeCells();
  }

  @keydown('ctrl+d')
  public keyRelax() {
    this.relaxCells();
  }

  public onClickDownload() {
    const { scopeId, pivotName } = this.props;

    // only one download is allowed at a time
    if (this.state.xlsDownloadStatus?.status === CONTEXT_PENDING) {
      return;
    }

    const tokenValue = serviceEnv.lastBearerToken();
    const requestGenerateReportUrl = `${API_BASE_URL}/context/${scopeId}/pivot/${pivotName}/export?token=${tokenValue}`;
    serviceEnv.axios
      .get<ReportStatus>(requestGenerateReportUrl)
      .then((rep) => {
        this.setState({
          xlsDownloadStatus: {
            id: rep.data.id,
            status: CONTEXT_PENDING,
          },
        });
      })
      .catch((e: AxiosError) => {
        const reportDownloadErrorMessage = 'An error has occued downloading your report';

        const errorPayload =
          e.response && e.stack && typeof e.response === 'object' && typeof e.stack === 'string'
            ? e.stack.concat(JSON.stringify(e.response))
            : e.stack;

        serviceEnv.loggingService.error(reportDownloadErrorMessage, errorPayload);
        toast.error(reportDownloadErrorMessage, {
          position: toast.POSITION.TOP_LEFT,
        });
      });
  }

  public onClickUndo(event?: React.MouseEvent<HTMLButtonElement>) {
    const { scopeId, onClickUndo } = this.props;
    if (onClickUndo && scopeId) {
      onClickUndo(scopeId).then(() => this.pivot!.setFocusedCell());
    }
  }

  public onClickRedo() {
    const { scopeId, onClickRedo } = this.props;
    if (onClickRedo && scopeId) {
      onClickRedo(scopeId).then(() => this.pivot!.setFocusedCell());
    }
  }

  public freezeCells = () => {
    const pivot = this.pivot;
    let cells: PivotCell[] = [];
    if (!pivot) {
      return;
    }

    cells = pivot.getSelectedCells();

    pivot.state.manager
      .lockCells(cells)
      .then((isGlobalLock) => {
        if (pivot.props.updateLockState) {
          return pivot.props.updateLockState(pivot.props.config.scopeId, isGlobalLock);
        }
        return false;
      })
      .then(() => {
        pivot.refreshCells();
        pivot.setState({ isEditing: false }, () => {
          pivot.gridApi.addEventListener('cellsReturned', this.updateContextMenu);
        });
      });
  };

  public lockCells = () => {
    const pivot = this.pivot;
    let cells: PivotCell[] = [];
    if (!pivot) {
      return;
    }
    cells = pivot.getSelectedCells();

    pivot.state.manager
      .constrainCells(cells)
      .then((isGlobalLock) => {
        if (pivot.props.updateLockState) {
          return pivot.props.updateLockState(pivot.props.config.scopeId, isGlobalLock);
        }
        return false;
      })
      .then(() => {
        pivot.refreshCells();

        pivot.setState({ isEditing: false }, () => {
          pivot.gridApi.addEventListener('cellsReturned', this.updateContextMenu);
        });
      });
  };

  public relaxCells = () => {
    const pivot = this.pivot;
    let cells: PivotCell[] = [];
    if (!pivot) {
      return;
    }
    cells = pivot.getSelectedCells();

    pivot.state.manager
      .relaxCells(cells)
      .then((isGlobalLock) => {
        if (pivot.props.updateLockState) {
          return pivot.props.updateLockState(pivot.props.config.scopeId, isGlobalLock);
        }
        return false;
      })
      .then(() => {
        pivot.refreshCells();
        pivot.setState({ isEditing: false }, () => {
          pivot.gridApi.addEventListener('cellsReturned', this.updateContextMenu);
        });
      });
  };

  public onLayoutManagerOpen() {
    this.setState({ isConfigModalOpen: true });
  }

  public onLayoutManagerClose() {
    this.setState({ isConfigModalOpen: false });
  }

  public onLayoutManagerSave() {
    const groupId = this.props.groupId;
    const mainConfig = this.props.mainConfig;
    if (!mainConfig || !this.configurator || isScopeNotReady(mainConfig)) {
      return;
    }

    const levelsMap = _.keyBy(_.flatMap(mainConfig.levels), 'id');
    const rows = this.configurator.state.groups[1];
    const columns = this.configurator.state.groups[2];
    const rGroups: Group[] = [];
    const cGroups: Group[] = [];

    for (const row of rows.children!) {
      rGroups.push(getUnFilteredGroupFromConfigItem(row, levelsMap));
    }
    for (const col of columns.children!) {
      cGroups.push(getUnFilteredGroupFromConfigItem(col, levelsMap));
    }
    const pivotConfig = new PivotConfig({
      scopeId: this.props.scopeId,
      rows: rGroups,
      columns: cGroups,
    });

    const faveOpts: PivotOptions = {
      rows: rows.children!.map((child) => configItemToSimpleFavorite(child, this.configurator)),
      columns: columns.children!.map((child) => configItemToSimpleFavorite(child, this.configurator)),
    };

    const effective = getEffeciveGroups(this.state.allAvailableListing, faveOpts);

    if (this.props.updateToFavorites) {
      const fe: FavoritesEntry = {
        key: this.props.favoriteId!,
        group: groupId,
        value: faveOpts,
      };
      this.props.updateToFavorites(serviceEnv.axios, fe);
    }

    this.setState({
      pivotConfig,
      isConfigModalOpen: false,
      rowGroup: effective.row,
      colGroup: effective.column,
      availableGroup: effective.available,
      configFromSubmit: false,
    });
  }

  public onLayoutManagerSubmit() {
    const mainConfig = this.props.mainConfig;
    if (!mainConfig || !this.configurator || isScopeNotReady(mainConfig)) {
      return;
    }

    const levelsMap = _.keyBy(_.flatMap(mainConfig.levels), 'id');
    const availalbe = this.configurator.state.groups[0].children;
    const rows = this.configurator.state.groups[1].children;
    const columns = this.configurator.state.groups[2].children;
    const rGroups: Group[] = [];
    const cGroups: Group[] = [];

    for (const row of rows!) {
      rGroups.push(getUnFilteredGroupFromConfigItem(row, levelsMap));
    }
    for (const col of columns!) {
      cGroups.push(getUnFilteredGroupFromConfigItem(col, levelsMap));
    }

    const pivotConfig = new PivotConfig({
      scopeId: this.props.scopeId,
      rows: rGroups,
      columns: cGroups,
    });

    this.setState(
      {
        pivotConfig,
        rowGroup: rows as ConfigGroup[],
        colGroup: columns as ConfigGroup[],
        availableGroup: availalbe as ConfigGroup[],
        isConfigModalOpen: false,
        configFromSubmit: true,
      },
      () => {
        this.updateContextMenu();
      }
    );
  }

  public onFavoritesAdd = (_e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const groupId = this.props.groupId;
    if (!this.configurator) {
      return;
    }
    if (typeof data.value !== 'string') {
      return;
    }

    const rows = this.configurator.state.groups[1];
    const columns = this.configurator.state.groups[2];

    const faveOpts: PivotOptions = {
      rows: rows.children!.map((child) => configItemToSimpleFavorite(child, this.configurator!)),
      columns: columns.children!.map((child) => configItemToSimpleFavorite(child, this.configurator!)),
    };

    if (this.props.addToFavorites) {
      const fe: FavoritesEntry = {
        key: data.value,
        group: groupId,
        value: faveOpts,
      };
      this.props.addToFavorites(serviceEnv.axios, fe);
    }
    this.setState({ favoriteValue: data.value });
  };

  public onFavoritesChange(newValue: string) {
    const keys = _.keyBy(this.props.favorites, 'key');
    const faveEntry: FavoritesEntry = keys[newValue];

    if (!faveEntry) {
      return;
    }

    const effective = getEffeciveGroups(this.state.allAvailableListing, faveEntry.value);

    this.setState({
      favoriteValue: newValue,
      rowGroup: effective.row,
      colGroup: effective.column,
      availableGroup: effective.available,
    });

    this.configurator.setState({
      groups: [
        {
          id: 'available',
          name: 'Dimension',
          children: _.cloneDeep(effective.available) || [],
        },
        {
          id: 'rows',
          name: 'Rows',
          children: _.cloneDeep(effective.row) || [],
        },
        {
          id: 'columns',
          name: 'Columns',
          children: _.cloneDeep(effective.column) || [],
        },
      ],
    });
  }

  public onPivotChange() {
    this.updateContextMenu();
  }

  public handleSetPivot = (pivot: IAgPivot) => {
    this.pivot = pivot;
  };

  public updateContextMenu() {
    const pivot = this.pivot;
    let cells: PivotCell[] = [];

    if (pivot) {
      cells = pivot.getSelectedCells();
    }

    const cellState = getCellStateFromCells(cells);

    const contextItems = (
      <VisualizeContextMenu
        hasEditableRevision={this.props.hasEditableRevision}
        cellState={cellState}
        favoritesLoading={this.state.xlsDownloadStatus?.status === CONTEXT_PENDING}
        gridAsyncState={this.props.gridAsyncState}
        availableGroup={this.state.availableGroup}
        handleClickUndo={this.onClickUndo}
        handleClickRedo={this.onClickRedo}
        handleClickLayoutManagerOpen={this.onLayoutManagerOpen}
        handleClickDownload={this.onClickDownload}
        freezeCells={this.freezeCells}
        lockCells={this.lockCells}
        relaxCells={this.relaxCells}
      />
    );

    this.setState({
      contextItems,
    });
    if (pivot && pivot.gridApi) {
      // remove the model listener (added to watch for when to refresh after locking/freezing/relaxing)
      pivot.gridApi.removeEventListener('modelUpdated', this.updateContextMenu);
    }
  }

  componentDidUpdate(prevProps: FavoriteProps, prevState: FavoriteState) {
    if (
      prevProps.gridAsyncState !== this.props.gridAsyncState ||
      !isEqual(prevState.xlsDownloadStatus, this.state.xlsDownloadStatus) ||
      !isEqual(prevState.favoriteValue, this.state.favoriteValue)
    ) {
      this.updateContextMenu();
    }
    if (this.state.xlsDownloadStatus) {
      const authToken = serviceEnv.lastBearerToken();
      this.props.reports.forEach((rep) => {
        if (rep.id === this.state.xlsDownloadStatus?.id && rep.status === CONTEXT_READY) {
          this.setState(
            {
              xlsDownloadStatus: undefined,
            },
            () => (window.location.href = `${API_BASE_URL}/drop/${rep.id}?token=${authToken}`)
          );
        }
      });
    }
  }

  public render() {
    const mainConfig = this.props.mainConfig;
    const { t } = this.props;
    if (isScopeNotReady(mainConfig) || !this.props.scopeReady) {
      return (
        <Segment className="visualize-summary-pivot-uninitialized">
          <Icon className="far fa-exclamation-circle" />
          Scope not ready. Please wait while your scope is prepared
        </Segment>
      );
    }
    if (!mainConfig) {
      return <React.Fragment />;
    }
    if (!this.props.initialized && !this.props.isFetchingScope && this.props.scopeReady) {
      return (
        <Segment className="visualize-summary-pivot-uninitialized">
          <Icon className="far fa-exclamation-circle" />
          Plan not initialized. Please Initialize the Plan
        </Segment>
      );
    }

    if (isNil(this.state.pivotConfig)) {
      return (
        <Segment className="visualize-summary-pivot-uninitialized">
          <Icon className="far fa-exclamation-circle" />
          {t('Favorite')}: &apos;{this.props.favoriteId}&apos; Not Found
        </Segment>
      );
    }

    const keys = Object.keys(_.keyBy(this.props.favorites, 'key'));
    const faveOptions = keys.map((key) => ({ value: key, text: key }));

    return (
      <div className="visualize-summary-pivot">
        <MfpSubheader
          title={t('Favorite') + ': ' + (this.props.favoriteId || '')}
          lockToggleParams={{
            active: this.props.isLockToggled,
            onClick: this.toggleLock,
            hidden: !this.props.hasEditableRevision,
          }}
        />
        <DraggableContainer>
          <MacroSummaries />
          <MinimizableItem title="" optionChildren={this.state.contextItems}>
            <GridErrorBoundary errorTitle={'Something has gone wrong with the grid'}>
              <AgPivot
                config={this.state.pivotConfig}
                onDataChange={this.onPivotChange}
                onSelectionChange={this.onPivotChange}
                onMount={this.onPivotChange}
                handleDownloadXLS={this.onClickDownload}
                handleSetPivot={this.handleSetPivot}
              />
            </GridErrorBoundary>
          </MinimizableItem>
        </DraggableContainer>
        <TitledModal title="Layout Manager" show={this.state.isConfigModalOpen} closeModal={this.onLayoutManagerClose}>
          <div className={'layout-modal-body'}>
            <PivotConfigurator
              // @ts-ignore
              ref={(el) => (this.configurator = el)}
              available={this.state.availableGroup}
              rows={this.state.rowGroup}
              columns={this.state.colGroup}
              settings={this.props.settings}
            />
          </div>
          <div className={'layout-footer'}>
            <Dropdown
              fluid={true}
              search={true}
              selection={true}
              allowAdditions={true}
              options={faveOptions}
              onAddItem={this.onFavoritesAdd}
              value={this.state.favoriteValue}
              onChange={(__, data) => this.onFavoritesChange(data.value as string)}
            />
            <Button content={'CANCEL'} onClick={this.onLayoutManagerClose} />
            <Button
              content={'SAVE'}
              icon={<i className={'fas fa-save'} />}
              className="layout-submit-modal-button"
              onClick={this.onLayoutManagerSave}
            />
            <Button content={'SUBMIT'} className="layout-submit-modal-button" onClick={this.onLayoutManagerSubmit} />
          </div>
        </TitledModal>
        {this.state.isSaving && <LoadingMask />}
      </div>
    );
  }
}
// @ts-ignore
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(makeMfpScopeSensitive(withTranslation(null, { withRef: true })(Favorite))));
