import React, { useState } from 'react';
import { isNil, mapValues, uniqBy, uniqWith } from 'lodash';
import { getVisualizeChartItem } from 'src/components/Visualize/Visualize.styles';
import ArrowValueRenderer, { ArrowValueRendererProps } from 'src/components/ArrowValueRenderer/ArrowValueRenderer';
import styles from 'src/components/ConfigurableGrid/Macros/Macros.styles';
import { ViewDataState } from 'src/types/Domain';
import { Skeleton } from '@material-ui/lab';
interface TopAttributesGroupButtonsProps {
  buttons: {
    label: string;
    selected: boolean;
  }[];
  onGroupSelection: (group: string) => void;
  sideNavCollapsed: boolean;
}

const TopAttributesGroupButtons = ({ buttons, onGroupSelection, sideNavCollapsed }: TopAttributesGroupButtonsProps) => {
  if (buttons.length <= 1) return <></>;
  return (
    <div className={styles.calcGroupButtonStyle(sideNavCollapsed)}>
      {buttons.map((btn, index, arr) => {
        const { label } = btn;
        const isFirstBtn = index === 0;
        const isLastBtn = index === arr.length - 1;

        let margin = '0 0.5rem';
        if (isFirstBtn) {
          margin = '0 0.5rem 0 0'; // no left margin
        } else if (isLastBtn) {
          margin = '0 0 0 0.5rem'; // no right margin
        }

        return (
          <span
            key={label}
            style={{ margin, userSelect: 'none', height: 'inherit' }}
            className={getVisualizeChartItem(btn.selected)}
            onClick={() => onGroupSelection(label)}
          >
            {label}
          </span>
        );
      })}
    </div>
  );
};

interface TopAttributesProps {
  topAttributes: ArrowValueRendererProps[];
  sideNavCollapsed: boolean;
}

export const TopAttributes = ({ topAttributes, sideNavCollapsed }: TopAttributesProps) => {
  const [groupSelections, setGroupSelections] = useState<Record<string, boolean>>(() => {
    const initialSelections = {};
    // setup default selections from config
    topAttributes.forEach(({ group, hidden, selected }) => {
      if (!hidden && !isNil(group)) {
        initialSelections[group] = selected;
      }
    });
    return initialSelections;
  });
  const availableTopAttributes = topAttributes.filter((m) => !m.hidden);
  const tATest = availableTopAttributes.map((ta) => ({
    label: ta.group ?? '',
    selected: groupSelections[ta.group ?? ''] ?? false,
  }));
  const topAttributesGroupButtons = uniqBy(
    availableTopAttributes.map((ta) => ({
      label: ta.group ?? '',
      selected: groupSelections[ta.group ?? ''] ?? false,
    })),
    'label'
  );
  const visibleAttributes = topAttributes.filter((m) => groupSelections[m.group ?? ''] === true);

  const onGroupSelection = (group: string) => {
    // FIXME: Only support single select for now (wanted for demo). Add multiselect as config option.
    const newGroupSelections = mapValues(groupSelections, (_, groupKey) => {
      return groupKey === group;
    });
    setGroupSelections(newGroupSelections);
    // Below is the multiselect method.
    // create new object so children depending on object's keys/values get rerendered properly
    // setGroupSelections({
    //   ...groupSelections,
    //   [group]: !groupSelections[group],
    // });
  };
  return (
    <React.Fragment>
      <TopAttributesGroupButtons
        buttons={topAttributesGroupButtons}
        onGroupSelection={onGroupSelection}
        sideNavCollapsed={sideNavCollapsed}
      />
      <div className={styles.calcTopAttributeStyle(sideNavCollapsed)}>
        {visibleAttributes.map((arrowProps) => (
          <ArrowValueRenderer {...arrowProps} key={arrowProps.header} />
        ))}
      </div>
    </React.Fragment>
  );
};
